import React from 'react';
import { Modal, Typography, Button, Box } from '@mui/material';

const ConfirmationModal = ({ open, onClose, onConfirm, title, content }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%', // Set a percentage width for responsiveness
          maxWidth: 400, // Set a maximum width for larger screens
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2">
          {title}
        </Typography>
        <Typography sx={{ mt: 2 }}>{content}</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button color="error" onClick={onClose}>
            Cancel
          </Button>
          <Button color="success" onClick={onConfirm} sx={{ ml: 1 }}>
            Proceed
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
