import React, { useState, useEffect } from "react";

import { checkAuthenticationModerator } from "../../Authentication/checkAuthentication";

const BoardModerator = () => {
  const [content, setContent] = useState("");

  useEffect(() => {
    //Call JWT checker each time user switches tabs
    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'visible') {
        try {
          // Check if the user still has an active session/jwt token/cookies or secret key
          const content = await checkAuthenticationModerator();
          setContent(content);
        } catch (error) {
          const _content = error.message || "Error checking authentication.";
          setContent(_content);
        }
      }
    };
  
    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);
  
    //Check anyway for authenticity of the user
    const content = checkAuthenticationModerator();

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <div className="container">
      <header className="jumbotron">
        <h3>{content}</h3>
      </header>
    </div>
  );
};

export default BoardModerator;
