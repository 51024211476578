import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import ItemForm from '../Forms/ItemForm';
import ModalWrapper from './ModalWrapper';

const ItemModal = ({ onClose, onAddItem, onEditItem, onUpdateSuccess, modalTitle = '', initialTitle, initialShortDescription = '', initialLongDescription = '', initialIsEventOpen = false, selectedItems, isFromBoardAdminEvents, isFromBoardAdminActions }) => {
  const [formData, setFormData] = useState({
    title: initialTitle,
    shortDescription: initialShortDescription,
    longDescription: initialLongDescription,
    isEventOpen: initialIsEventOpen,
    image: null,
  });
  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleFormSubmit = () => {
    onAddItem(formData);
    handleClose();
  };

  const handleFormUpdate = () => {
    onEditItem(formData);
    handleClose();
  };

  const handleUpdateSuccess = (updatedData) => {
    // Execute the onUpdateSuccess callback with the updated data
    onUpdateSuccess(updatedData);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const handleCancel = () => {
    handleClose();
  };

  return (
    <ModalWrapper open={isModalOpen} onClose={handleClose}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        {modalTitle}
      </Typography>
      <ItemForm
        formData={formData}
        setFormData={setFormData}
        onFormSubmit={handleFormSubmit}
        onFormUpdate={handleFormUpdate}
        onUpdateSuccess={handleUpdateSuccess}
        onCancel={handleCancel}
        selectedItems={selectedItems || undefined} 
        isFromBoardAdminEvents={isFromBoardAdminEvents}
        isFromBoardAdminActions={isFromBoardAdminActions}
      />
    </ModalWrapper>
  );
};

export default ItemModal;