import React from "react";
import { styled } from '@mui/material/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

import CustomTypography from './UI/Typography/CustomTypography';

const theme = createTheme({
  shape: {
    borderRadius: 5
  },
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#F9F9F9',
  ...theme.typography.body2,
  padding: theme.spacing(3),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Contact = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const name = formData.get('name');
    const email = formData.get('email');
    const subject = formData.get('subject');
    const message = formData.get('message');
    const mailtoLink = `mailto:Hector@hellenicpolice.gr?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message + '\n\nFrom: ' + name + '\nEmail: ' + email)}`;
    window.open(mailtoLink, '_blank');
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl">
        <Box sx={{ flexGrow: 1, bgcolor: 'white' }}>
          <Grid container spacing={0}>

            {/* Contact Us Header */}
            <Grid container spacing={2} style={{ paddingBottom: '20px' }}>
              <Grid item xs={12}>
                <Item style={{ paddingTop: '130px', paddingBottom: '230px', backgroundImage: 'url("/images/BannerImages/6.jpg")', backgroundSize: 'cover', backgroundPosition: '50% 70%' }}>
                </Item>
              </Grid>
            </Grid>

            {/* Contact Us Section */}
            <Grid container spacing={2} style={{ paddingTop: '10px' }}>
              <Grid item xs={12}>
                <Box textAlign="center">
                  <CustomTypography variant="h3" text="Get In Touch" color="#0651A0" underline={true} />
                  <br></br>
                  <CustomTypography variant="h4" text="Want to contact us?" color="grey" />
                  <CustomTypography variant="h4" text="Use the form below." color="grey" />

                </Box>
              </Grid>
            </Grid>

            {/* Contact Form Section */}
            <Grid item xs={12} md={12}>
              <Item>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <TextField name="name" label="Your Name" fullWidth variant="outlined" />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField name="email" label="Your Email" fullWidth variant="outlined" />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField name="subject" label="Subject" fullWidth variant="outlined" />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField name="message" label="Message" multiline rows={4} fullWidth variant="outlined" />
                    </Grid>
                    <Grid item xs={12}>
                      <Button type="submit" variant="contained" color="primary" fullWidth>
                        Send Message
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Item>
            </Grid>
          </Grid>

          {/* Footer Section */}
          <Grid container spacing={2} style={{ paddingTop: '50px' }}>
            <Grid item xs={12} sm={12} md={12}>
              <Item style={{ paddingTop: '50px', paddingBottom: '50px', backgroundImage: 'url("/images/BannerImages/footer.jpg")', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" padding="20px">
                  <Box display="flex" alignItems="center">
                    <img src="/images/eu-flag.png" alt="European Union Flag" style={{ width: '100px', height: 'auto', marginRight: '10px' }} />
                  </Box>
                  <Box display="flex" flexDirection="column" alignItems="flex-start" flex="1">
                    <CustomTypography variant="h7" text="This project is funded by the European Union’s Internal Security Fund — Police." color="white" />
                  </Box>
                  <Box display="flex" flexDirection="column" alignItems="center" flex="1">
                    <CustomTypography variant="h7" text="Grant Agreement No. 101100558 — PROJECT HECTOR" color="white" />
                  </Box>
                  <Box display="flex" flexDirection="column" alignItems="flex-end" flex="1">
                    <CustomTypography variant="h7" text="The content of this webpage represents the views of the author only and is his/her sole responsibility. The European Commission does not accept any responsibility for use that may be made of the information it contains." color="white" />
                  </Box>
                </Box>
              </Item>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider >
  );
};

export default Contact;
