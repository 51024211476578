import React from 'react';
import Slider from 'react-slick';
import Box from '@mui/material/Box';

const PartnerCarousel = ({ partnerImages }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Slider {...settings}>
      {partnerImages.map((partner, index) => (
        <a key={index} href={partner.url} target="_blank" rel="noopener noreferrer">
          <img src={`/images/Partners/${partner.filename}`} alt={`Partner ${index + 1}`} style={{ width: '100%', height: '100px', objectFit: 'contain' }} />
        </a>
      ))}
    </Slider>
  );
};

export default PartnerCarousel;
