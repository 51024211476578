import React, { useState, useEffect } from "react";
import axios from 'axios';

import { API_URL } from '../../../apiConfig';
import { useNavigate } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import ItemShowCaseModal from '../Modal/ItemShowCaseModal';
import ShareModal from '../Modal/ShareModal';

const truncateText = (text, maxLength) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(text, 'text/html');
  const plainText = doc.body.textContent || "";

  if (plainText.length <= maxLength) {
    return plainText;
  }

  return plainText.substring(0, maxLength) + '...';
};

const CustomCard = ({ id, image, title, date, heading, body, action2, fontColor, maxWidth }) => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);

  // Fetch Data with specific News ID
  const fetchNewsById = (id) => {
    axios.get(`${API_URL}/news/${id}`)
      .then((response) => {
        setSelectedNews(response.data);
      })
      .catch((error) => {
        console.error('Error fetching news information:', error);
      });
  };

  // Format The Date for the specific News id you fetched
  const formattedDate = selectedNews ?
    new Date(selectedNews.updatedAt).toLocaleDateString('el-GR', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }) :
    '';

  // Function to generate a dynamic link based on the news title
  const generateDynamicLink = (title) => {
    // Replace spaces with dashes and convert to lowercase for a URL-friendly format
    const formattedTitle = title.replace(/\s+/g, '-').toLowerCase();
    return API_URL+`/news/+${formattedTitle}-${id}`;
  };


  const handleLearnMoreClick = () => {
    fetchNewsById(id);
    setModalOpen(true);
    navigate('/project');
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // Function to open the share modal
  const handleOpenShareModal = () => {
    setShareModalOpen(true);
  };

  // Function to close the share modal
  const handleCloseShareModal = () => {
    setShareModalOpen(false);
  };

  useEffect(() => {
    // Additional logic as needed
  }, [selectedNews]);

  return (
    <Card sx={{ maxWidth: maxWidth || 300, width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardMedia
        sx={{ height: 144, objectFit: 'cover' }} // Adjust objectFit as needed
        component="img" // Use 'img' tag for image
        alt={title}
        src={image} // Image source
      />
      <CardContent sx={{ flex: 1 }}>
        <Typography gutterBottom variant="h5" component="div" color={fontColor} sx={{ overflowWrap: 'break-word' }}>
          {truncateText(heading, 50)}
        </Typography>

        <Typography variant="body2" color="text.secondary" sx={{ overflowWrap: 'break-word', fontWeight: 'bold' }}>
          {date} {/* Use the formatted date here */}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ overflowWrap: 'break-word' }}>
          {truncateText(body, 120)}
        </Typography>
      </CardContent>
      <CardActions sx={{ position: 'sticky', bottom: 0, backgroundColor: 'white', justifyContent: 'center' }}>
        <Button size="small" onClick={() => { fetchNewsById(id); handleLearnMoreClick(); }}>
          Learn More
        </Button>
        <Button size="small" onClick={handleOpenShareModal}>
          {action2}
        </Button>
      </CardActions>

      {/* News ShowCase Modal */}
      {selectedNews && (
        <ItemShowCaseModal
          open={modalOpen}
          onClose={handleCloseModal}
          title={title}
          longDescription={selectedNews.longDescription}
          date={formattedDate}
          image={image}
          id={id}
        />
      )}

      <ShareModal
        open={shareModalOpen}
        onClose={handleCloseShareModal}
        link={generateDynamicLink(title)}
        id={id}
      />
    </Card>
  );
};

export default CustomCard;
