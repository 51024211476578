import React, { useState, useEffect } from "react";
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { API_URL } from '../apiConfig';
import { useSelector } from "react-redux";

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CustomTypography from './UI/Typography/CustomTypography';
import EventsComplexCard from './UI/Cards/EventsComplexCard';
import CustomPager from './UI/Pagination/CustomPager';
import JoinEventButton from './UI/Buttons/JoinEventButton';

const theme = createTheme({
  shape: {
    borderRadius: 5
  },
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#F9F9F9',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,

  display: 'flex', // Make it a flex container
  flexDirection: 'column', // Align children in a column
  alignItems: 'center', // Center items horizontally
  justifyContent: 'center', // Center items vertically
}));

const Events = () => {
  const [latestEvents, setLatestEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;
  const currentUser = useSelector((state) => state.auth.user);
  const [eventsIds, setEventsIds] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const paginatedEvents = latestEvents.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  //Fetch ONLY the ids of all events to fill the pager 
  const fetchAllEventsIds = async () => {
    try {
      const response = await axios.get(API_URL + '/events/ids');
      const idsArray = response.data.map(eventsItem => eventsItem.id);
      setEventsIds(idsArray);
      setTotalItems(idsArray.length);
    } catch (error) {
      console.error("Error fetching all events IDs:", error);
      // Handle error if needed
    }
  };

  //Then get 4 Events per page 
  const fetchEvents = async () => {
    try {
      const response = await axios.get(API_URL + '/events/paged', {
        params: {
          page: currentPage,
          itemsPerPage: itemsPerPage,
        },
      });

      // Access the 'rows' property in the response
      const eventsArray = response.data.rows.map((eventsItem) => ({
        id: eventsItem.id,
        title: eventsItem.title,
        shortDescription: eventsItem.shortDescription,
        isEventOpen: eventsItem.isEventOpen,
        date: new Date(eventsItem.updatedAt),
        image: eventsItem.image,
        imageURL: eventsItem.imageURL,
      }));

      // Sort eventsArray by ID in descending order
      const sortedEventsArray = eventsArray.sort((a, b) => b.id - a.id);
      // Get the latest events items
      setLatestEvents(sortedEventsArray);
    } catch (error) {
      console.error("Error fetching events:", error);
      // Handle error if needed
    }
  };

  useEffect(() => {
    fetchAllEventsIds();
    fetchEvents();
  }, [currentPage]); // Trigger fetchEvents when currentPage changes

  useEffect(() => {
    // For example, you can console.log the latestEvents to verify if it's updating correctly.
  }, [latestEvents]);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xxl">
        <Box sx={{ flexGrow: 1, bgcolor: 'white' }}>

          {/* Events List Header */}
          <Grid container spacing={2} style={{ paddingTop: '50px' }}>
            <Grid item xs={12}>
              <Item style={{ paddingTop: '130px', paddingBottom: '130px', backgroundImage: 'url("/images/BannerImages/7.jpg")', backgroundPosition: 'center' }}>
              </Item>
            </Grid>
          </Grid>

          {/* Events List Section */}
          <Grid container spacing={2} style={{ marginBottom: '25px', marginTop: '25px' }}>
            <Grid item xs={12}>
              <Box textAlign="center">
                <CustomTypography variant="h3" text="Events List" color="#0651A0" underlineThickness={3} underline={true} />
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ paddingTop: '50px', justifyContent: 'center', alignItems: 'center' }}>
            {latestEvents.map((eventsItem, index) => (
              <Grid item xs={12} sm={6} md={6} lg={2} key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <EventsComplexCard
                  avatarText={currentUser ? currentUser.username.charAt(0).toUpperCase() : 'R'}
                  avatarBgColor='teal'
                  actionIcon
                  title={eventsItem.title}
                  subheader={eventsItem.date.toLocaleDateString('el-GR', {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                  })}
                  image={eventsItem.imageURL}
                  content={eventsItem.shortDescription}
                  id={eventsItem.id}
                  action1="Learn More"
                  action2="Share"
                />
                {eventsItem.isEventOpen === true ? (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '10px' }}>
                    <JoinEventButton variant={'contained'}>Join Event</JoinEventButton>
                  </div>
                ) : (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '10px' }}>
                    <JoinEventButton variant={'contained'} disabled={true}>
                      Event Closed
                    </JoinEventButton>
                  </div>
                )}
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={2} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', paddingTop: '50px' }}>
              <CustomPager
                pageCount={Math.ceil(totalItems / itemsPerPage)}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </Grid>
          </Grid>

          {/* Footer Section */}
          <Grid container spacing={2} style={{ paddingTop: '50px' }}>
            <Grid item xs={12} sm={12} md={12}>
              <Item style={{ paddingTop: '50px', paddingBottom: '50px', backgroundImage: 'url("/images/BannerImages/footer.jpg")', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" padding="20px">
                  <Box display="flex" alignItems="center">
                    <img src="/images/eu-flag.png" alt="European Union Flag" style={{ width: '100px', height: 'auto', marginRight: '10px' }} />
                  </Box>
                  <Box display="flex" flexDirection="column" alignItems="flex-start" flex="1">
                    <CustomTypography variant="h7" text="This project is funded by the European Union’s Internal Security Fund — Police." color="white" />
                  </Box>
                  <Box display="flex" flexDirection="column" alignItems="center" flex="1">
                    <CustomTypography variant="h7" text="Grant Agreement No. 101100558 — PROJECT HECTOR" color="white" />
                  </Box>
                  <Box display="flex" flexDirection="column" alignItems="flex-end" flex="1">
                    <CustomTypography variant="h7" text="The content of this webpage represents the views of the author only and is his/her sole responsibility. The European Commission does not accept any responsibility for use that may be made of the information it contains." color="white" />
                  </Box>
                </Box>
              </Item>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Events;
