import React, { useState, useEffect } from "react";
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { TextField, Button, Grid } from '@mui/material';
import CustomButton from '../Buttons/NewsLetterButton';
import CustomCheckBox from "../CheckBoxes/CustomCheckBox";

//TODO:Must add onFormSubmit if i am on submit mode and onUpdateSuccess when i am on success mode
const NewsletterModal = ({ modalTitle, open, onClose, onFormSubmit, onUpdateSuccess, selectedItems, isFromBoardAdminPartners }) => {
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    phone: '',
    organizationName: '',
    comments: '',
    isAccepted: ''
  });

  const [fieldErrors, setFieldErrors] = useState({
    name: '',
    surname: '',
    email: '',
    organizationName: '',
  });

  const [canSubmit, setCanSubmit] = useState(false);

  useEffect(() => {
    // Update the form data when selectedItems changes
    setFormData({
      name: selectedItems ? selectedItems.partnerName : '',
      surname: selectedItems ? selectedItems.partnerSurname : '',
      email: selectedItems ? selectedItems.partnerEmail : '',
      phone: selectedItems ? selectedItems.partnerPhone : '',
      organizationName: selectedItems ? selectedItems.partnerOrganizationName : '',
      comments: selectedItems ? selectedItems.partnerComments : '',
      isAccepted: selectedItems ? selectedItems.isAccepted : ''
    });
  }, [selectedItems]);

  useEffect(() => {
    // Check if all mandatory fields are filled
    const mandatoryFields = ['name', 'surname', 'email', 'organizationName'];
    const isValid = mandatoryFields.every(field => formData[field] !== '');
    setCanSubmit(isValid);
  }, [formData]);

  const handleChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));

    // Clear error when user starts typing
    setFieldErrors((prevFieldErrors) => ({
      ...prevFieldErrors,
      [field]: '',
    }));
  };

  const handleSubmit = () => {
    // Check if all mandatory fields are filled
    const mandatoryFields = ['name', 'surname', 'email', 'organizationName'];
    const newFieldErrors = {};
    mandatoryFields.forEach(field => {
      if (formData[field] === '') {
        newFieldErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
      }
    });

    // Display errors if any mandatory fields are not filled
    if (Object.keys(newFieldErrors).length > 0) {
      setFieldErrors(newFieldErrors);
    } else {
      // Submit the form if all mandatory fields are filled
      onUpdateSuccess(formData);
      onClose();
      setFormData({
        name: '',
        surname: '',
        email: '',
        phone: '',
        organizationName: '',
        comments: '',
        isAccepted: ''
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{modalTitle}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              required
              value={formData.name}
              onChange={(e) => handleChange('name', e.target.value)}
              error={!!fieldErrors.name}
              helperText={fieldErrors.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Surname"
              variant="outlined"
              fullWidth
              required
              value={formData.surname}
              onChange={(e) => handleChange('surname', e.target.value)}
              error={!!fieldErrors.surname}
              helperText={fieldErrors.surname}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              type="email"
              required
              value={formData.email}
              onChange={(e) => handleChange('email', e.target.value)}
              error={!!fieldErrors.email}
              helperText={fieldErrors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Phone"
              variant="outlined"
              fullWidth
              type="tel"
              value={formData.phone}
              onChange={(e) => handleChange('phone', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Organization Name"
              variant="outlined"
              fullWidth
              required
              value={formData.organizationName}
              onChange={(e) => handleChange('organizationName', e.target.value)}
              error={!!fieldErrors.organizationName}
              helperText={fieldErrors.organizationName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Comments"
              variant="outlined"
              fullWidth
              multiline
              value={formData.comments}
              onChange={(e) => handleChange('comments', e.target.value)}
            />
          </Grid>

          {isFromBoardAdminPartners && (
            <Grid item xs={6}>
              <CustomCheckBox
                checkboxLabel="Accepted Partner"
                isChecked={formData.isAccepted || false}
                onChange={(isChecked) => setFormData({ ...formData, isAccepted: isChecked })}
              />
            </Grid>
          )}

        </Grid>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={onClose}>Cancel</CustomButton>
        <CustomButton variant="contained" onClick={handleSubmit} disabled={!canSubmit}>Submit</CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default NewsletterModal;