import React, { useState } from 'react';
import axios from 'axios';

import { API_URL } from '../../../apiConfig';

import { styled } from '@mui/system';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import ActionsShowCaseModal from '../Modal/ItemShowCaseModal';
import ShareModal from '../Modal/ShareModal';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ActionsComplexCard = ({
  id,
  avatarText,
  avatarBgColor,
  actionIcon,
  title,
  subheader,
  pdf,
  content,
  viewAction,
  shareAction,
  methodContent,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [selectedActions, setSelectedActions] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);

  // Fetch Data with specific Actions ID
  const fetchActionsById = (id) => {
    axios.get(`${API_URL}/actions/${id}`)
      .then((response) => {
        setSelectedActions(response.data);
      })
      .catch((error) => {
        console.error('Error fetching actions information:', error);
      });
  };

  // Format The Date for the specific Actions id you fetched
  const formattedDate = selectedActions ?
    new Date(selectedActions.updatedAt).toLocaleDateString('el-GR', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }) :
    '';

  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };

  // Function to be called when viewAction is triggered
  const handleLearnMoreClick = () => {
    fetchActionsById(id);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // Function to open the share modal
  const handleOpenShareModal = () => {
    setShareModalOpen(true);
  };

  // Function to close the share modal
  const handleCloseShareModal = () => {
    setShareModalOpen(false);
  };

  // Function to generate a dynamic link based on the actions title
  const generateDynamicLink = (title) => {
    // Replace spaces with dashes and convert to lowercase for a URL-friendly format
    const formattedTitle = title.replace(/\s+/g, '-').toLowerCase();
    return API_URL + `/actions/+${formattedTitle}-${id}`;
  };

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardHeader
        sx={{
          height: 150,
          overflow: 'hidden',
        }}
        titleTypographyProps={{
          variant: 'h6',
          style: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal', // Allow the title to wrap to the next line
          },
        }}
        action={
          <IconButton aria-label="settings">
            {/* {actionIcon} */}
          </IconButton>
        }
        title={title.length > 50 ? `${title.slice(0, 50)}...` : title}
        subheader={subheader}
      />
      {/* Display the first page of the PDF using <embed> */}
      {pdf && (
        <iframe
          src={pdf} // Assuming that pdf is the URL of your PDF file
          type="application/pdf"
          width="100%"
          height="200"
        />
      )}
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {content && content.length > 50 ? `${content.slice(0, 120)}...` : content}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        {/* IconButton for adding to views */}
        <IconButton aria-label="add to views" onClick={() => { fetchActionsById(id); handleLearnMoreClick(); }}>
          <VisibilityIcon />
        </IconButton>
        <IconButton aria-label="share" onClick={handleOpenShareModal}>
          <ShareIcon />
        </IconButton>
        {/* Functionality to display more info */}
        {/* <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore> */}
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>Method:</Typography>
          <Typography paragraph>

            <Typography paragraph>
              {methodContent}
            </Typography>
          </Typography>
        </CardContent>
      </Collapse>
      {/* Actions ShowCase Modal */}
      {selectedActions && (
        <ActionsShowCaseModal
          open={modalOpen}
          onClose={handleCloseModal}
          title={title}
          longDescription={selectedActions.longDescription}
          date={formattedDate}
          file={pdf}
          isCalledFromActionsCard={true}
        />
      )}
      <ShareModal
        open={shareModalOpen}
        onClose={handleCloseShareModal}
        link={generateDynamicLink(title)}
        id={id}
        isCalledFrom='actions'
      />
    </Card>
  );
};
export default ActionsComplexCard;
