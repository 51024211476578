import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MailIcon from "@mui/icons-material/Mail";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import { SITE_URL } from "../../../apiConfig";

const ShareModal = ({ open, onClose, id, isCalledFrom }) => {
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const link = `${SITE_URL}/${isCalledFrom}/${id}`;

  const handleEmailShare = () => {
    const subject = "Check out this link!";
    const body = `I thought you might find this interesting: ${link}`;
    const mailtoUrl = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoUrl;
    onClose();
  };

  const socialMediaOptions = [
    // { name: "Facebook", icon: <FacebookIcon />, url: `https://www.facebook.com/sharer/sharer.php?u=${link}` },
    { name: "Email", icon: <MailIcon />, action: handleEmailShare }, // Added email option
    { name: "Twitter", icon: <TwitterIcon />, url: `https://twitter.com/intent/tweet?url=${link}` },
    { name: "LinkedIn", icon: <LinkedInIcon />, url: `https://www.linkedin.com/shareArticle?url=${link}` },
    // Add more social media options as needed
  ];

  const handleShare = (socialMediaUrl) => {
    window.open(socialMediaUrl, "_blank");
    onClose();
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link).then(
      () => {
        setIsLinkCopied(true);
      },
      (err) => {
        console.error("Unable to copy text to clipboard", err);
      }
    );
  };

  const handleClose = () => {
    setIsLinkCopied(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        Share this link
        <IconButton sx={{ position: 'absolute', right: 0, top: 0 }} onClick={handleClose} color="primary">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Choose a method to share the link:
        </DialogContentText>
        <DialogActions>
          <Grid container spacing={2} justifyContent="center">
            {socialMediaOptions.map((option) => (
              <Grid item key={option.name}>
                <IconButton onClick={() => option.action ? option.action() : handleShare(option.url)} color="primary">
                  {option.icon}
                  {option.name}
                </IconButton>
              </Grid>
            ))}
            <Grid item>
              <IconButton onClick={handleCopyLink} color="primary">
                Copy Link
              </IconButton>
            </Grid>
          </Grid>
        </DialogActions>
        {isLinkCopied && (
          <DialogContentText style={{ color: "green", marginTop: "10px" }}>
            Link copied to clipboard!
          </DialogContentText>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ShareModal;