import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Fab, AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemText, ListItemIcon, Divider, Popover, Collapse } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import Newspaper from '@mui/icons-material/NewspaperOutlined';
import AdminPanelSettings from '@mui/icons-material/AdminPanelSettingsOutlined';
import ModeratorPanelSettings from '@mui/icons-material/AddModeratorOutlined';
import SupervisorAccount from '@mui/icons-material/SupervisorAccountOutlined';
import Settings from '@mui/icons-material/SettingsAccessibilityOutlined';
import PowerSettingsNew from '@mui/icons-material/PowerSettingsNew';
import SensorOccupied from '@mui/icons-material/SensorOccupiedOutlined';
import Diversity2Icon from '@mui/icons-material/Diversity2'; import Contact from '@mui/icons-material/ContactPageOutlined';
import Event from '@mui/icons-material/Event';
import LocalActivity from '@mui/icons-material/LocalActivity';
import Partners from '@mui/icons-material/PeopleOutline';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/X';

const Navbar = ({ showModeratorBoard, showAdminBoard, currentUser, logOut }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [adminBoardOpen, setAdminBoardOpen] = useState(false);
  const [adminMenuAnchor, setAdminMenuAnchor] = useState(null);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const scrollTopButton = (
    <Fab
      color="primary"
      aria-label="scroll-to-top"
      sx={{
        position: 'fixed',
        bottom: 16,
        right: 16,
      }}
      onClick={scrollToTop}
    >
      <ArrowUpwardIcon />
    </Fab>
  );

  const toggleAdminBoard = () => {
    setAdminBoardOpen(!adminBoardOpen);
  };

  const handleAdminMenuClick = (event) => {
    setAdminMenuAnchor(event.currentTarget);
  };

  const handleAdminMenuClose = () => {
    setAdminMenuAnchor(null);
  };

  const adminMenu = (
    <Popover
      anchorEl={adminMenuAnchor}
      open={Boolean(adminMenuAnchor)}
      onClose={handleAdminMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <List>
        <ListItem button component={Link} to="/admin/news" onClick={handleAdminMenuClose}>
          <ListItemText primary="News" />
        </ListItem>
        <ListItem button component={Link} to="/admin/actions" onClick={handleAdminMenuClose}>
          <ListItemText primary="Actions" />
        </ListItem>
        <ListItem button component={Link} to="/admin/upcoming-events" onClick={handleAdminMenuClose}>
          <ListItemText primary="Upcoming Events" />
        </ListItem>
      </List>
    </Popover>
  );

  const drawerList = (
    <List>
      <ListItem button component={Link} to={"/home"} onClick={() => setDrawerOpen(false)} sx={{ marginBottom: 2 }}>
        <ListItemIcon>
          <HomeIcon sx={{ color: 'white' }} />
        </ListItemIcon>
        <ListItemText primary="Home" sx={{ color: 'white' }} />
      </ListItem>

      <ListItem button component={Link} to={"/project"} onClick={() => setDrawerOpen(false)} sx={{ marginBottom: 2 }}>
        <ListItemIcon>
          <Diversity2Icon sx={{ color: 'white' }} />
        </ListItemIcon>
        <ListItemText primary="Project" sx={{ color: 'white' }} />
      </ListItem>

      <ListItem button component={Link} to={"/contact"} onClick={() => setDrawerOpen(false)} sx={{ marginBottom: 2 }}>
        <ListItemIcon>
          <Contact sx={{ color: 'white' }} />
        </ListItemIcon>
        <ListItemText primary="Contact Us" sx={{ color: 'white' }} />
      </ListItem>

      <ListItem button component={Link} to={"/news"} onClick={() => setDrawerOpen(false)} sx={{ marginBottom: 2 }}>
        <ListItemIcon>
          <Newspaper sx={{ color: 'white' }} />
        </ListItemIcon>
        <ListItemText primary="News" sx={{ color: 'white' }} />
      </ListItem>

      <ListItem button component={Link} to={"/actions"} onClick={() => setDrawerOpen(false)} sx={{ marginBottom: 2 }}>
        <ListItemIcon>
          <LocalActivity sx={{ color: 'white' }} />
        </ListItemIcon>
        <ListItemText primary="Actions" sx={{ color: 'white' }} />
      </ListItem>

      <ListItem button component={Link} to={"/events"} onClick={() => setDrawerOpen(false)} sx={{ marginBottom: 2 }}>
        <ListItemIcon>
          <Event sx={{ color: 'white' }} />
        </ListItemIcon>
        <ListItemText primary="Events" sx={{ color: 'white' }} />
      </ListItem>

      <Divider /><Divider />
      {showModeratorBoard && (
        <ListItem button component={Link} to={"/mod"} onClick={() => setDrawerOpen(false)} sx={{ marginBottom: 2 }}>
          <ListItemIcon>
            <ModeratorPanelSettings sx={{ color: 'white' }} />
          </ListItemIcon>
          <ListItemText primary="Moderator Board" sx={{ color: 'white' }} />
        </ListItem>
      )}

      {showAdminBoard && (
        <div>
          <ListItem button onClick={toggleAdminBoard} sx={{ marginBottom: 2 }}>
            <ListItemIcon>
              <AdminPanelSettings sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="Admin Board" sx={{ color: 'white' }} />
          </ListItem>

          <Collapse in={adminBoardOpen} timeout="auto" unmountOnExit>
            <List>
              <ListItem button component={Link} to={"/adminNews"} onClick={() => setDrawerOpen(false)} sx={{ marginBottom: 2, paddingLeft: 5 }}>
                <ListItemIcon>
                  <Newspaper sx={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText primary="News" sx={{ color: 'white' }} />
              </ListItem>

              <ListItem button component={Link} to={"/adminActions"} onClick={() => setDrawerOpen(false)} sx={{ marginBottom: 2, paddingLeft: 5 }}>
                <ListItemIcon>
                  <LocalActivity sx={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText primary="Actions" sx={{ color: 'white' }} />
              </ListItem>

              <ListItem button component={Link} to={"/adminEvents"} onClick={() => setDrawerOpen(false)} sx={{ marginBottom: 2, paddingLeft: 5 }}>
                <ListItemIcon>
                  <Event sx={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText primary="Events" sx={{ color: 'white' }} />
              </ListItem>

              <ListItem button component={Link} to={"/adminPartners"} onClick={() => setDrawerOpen(false)} sx={{ marginBottom: 2, paddingLeft: 5 }}>
                <ListItemIcon>
                  <Partners sx={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText primary="Partners" sx={{ color: 'white' }} />
              </ListItem>
            </List>
          </Collapse>
        </div>
      )}

      {currentUser && (
        <ListItem button component={Link} to={"/user"} onClick={() => setDrawerOpen(false)} sx={{ marginBottom: 2 }}>
          <ListItemIcon>
            <SupervisorAccount sx={{ color: 'white' }} />
          </ListItemIcon>
          <ListItemText primary="User Board" sx={{ color: 'white' }} />
        </ListItem>
      )}
    </List>
  );

  const drawerBottomList = (
    <List sx={{ marginTop: 'auto' }}>
      <Divider /><Divider />
      {currentUser && (
        <ListItem button onClick={logOut} sx={{ marginBottom: 2 }}>
          <ListItemIcon>
            <PowerSettingsNew sx={{ color: 'white' }} />
          </ListItemIcon>
          <ListItemText primary="LogOut" sx={{ color: 'white' }} />
        </ListItem>
      )}
    </List>
  );

  return (
    <div>
      <AppBar position="fixed" sx={{ bgcolor: '#363636' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }} component={Link} to={"/"}>
            <img src="/images/logo.png" alt="Logo" style={{ width: '55px', height: '55px', marginRight: '8px' }} />
            HECTOR Project
          </Typography>
          <div style={{ marginLeft: 'auto' }}>
            {/* <IconButton color="inherit" component="a" href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <FacebookIcon />
            </IconButton> */}
            <IconButton color="inherit" component="a" href="https://www.youtube.com/@Hector-Project" target="_blank" rel="noopener noreferrer">
              <YouTubeIcon />
            </IconButton>
            <IconButton color="inherit" component="a" href="https://www.linkedin.com/company/hector-isf" target="_blank" rel="noopener noreferrer">
              <LinkedInIcon />
            </IconButton>
            <IconButton color="inherit" component="a" href="https://x.com/Hector_ISF" target="_blank" rel="noopener noreferrer">
              <TwitterIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: '#1f579c',
          },
        }}
      >
        {drawerList}
        {drawerBottomList}
      </Drawer>
      {scrollTopButton}
    </div>
  );
};

export default Navbar;
