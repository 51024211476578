import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const ModalWrapper = ({ open, onClose, children }) => {
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={null}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%', // Set a percentage of the width
        maxWidth: '1000px', // Set a maximum width
        height: '80%', // Set a percentage of the height
        maxHeight: '800px', // Set a maximum height
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'auto', // Enable scrolling inside the modal
      }}>
        {children}
      </Box>
    </Modal>
  );
};

export default ModalWrapper;
