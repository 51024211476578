import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import NewsComplexCard from '../Cards/NewsComplexCard';
import { API_URL } from '../../../apiConfig';

const newsCardVariants = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
};

const LatestNewsListSection = () => {
    const [latestNews, setLatestNews] = useState([]);
    const { ref, inView } = useInView({ triggerOnce: true });

    // Fetch latest news data
    useEffect(() => {
        axios.get(`${API_URL}/news/all`, {
            params: {},
        })
            .then((response) => {
                const newsArray = Object.values(response.data).map(newsItem => ({
                    id: newsItem.id,
                    title: newsItem.title,
                    shortDescription: newsItem.shortDescription,
                    image: newsItem.image,
                    imageURL: newsItem.imageURL,
                    date: new Date(newsItem.updatedAt),
                }));

                // Sort newsArray by ID in descending order
                const sortedNewsArray = newsArray.sort((a, b) => b.id - a.id);

                // Get the latest 5 news items
                const latestNewsItems = sortedNewsArray.slice(0, 5);

                setLatestNews(latestNewsItems);
            });
    }, []);

    return (
        <Grid container spacing={2} style={{ paddingTop: '50px', paddingBottom: '150px', justifyContent: 'center', alignItems: 'center' }}>
            {latestNews.map((newsItem, index) => (
                <Grid item xs={12} sm={6} md={6} lg={2} key={index} style={{ display: 'flex', justifyContent: 'center' }}>
                    <motion.div
                        ref={ref}
                        initial="hidden"
                        animate={inView ? 'visible' : 'hidden'}
                        variants={newsCardVariants}
                    >
                        <NewsComplexCard
                            avatarBgColor='#0651A0'
                            actionIcon
                            title={newsItem.title}
                            subheader={newsItem.date.toLocaleDateString('el-GR', {
                                day: 'numeric',
                                month: 'numeric',
                                year: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric'
                            })}
                            image={newsItem.imageURL}
                            content={newsItem.shortDescription}
                            id={newsItem.id}
                            action1="Learn More"
                            action2="Share"
                        />
                    </motion.div>
                </Grid>
            ))}
        </Grid>
    );
};

export default LatestNewsListSection;
