import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useSelector } from "react-redux";

import Box from '@mui/material/Box';
import Container from "@mui/material/Container";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';

import { API_URL } from '../../../apiConfig';

import CustomTypography from '../../UI/Typography/CustomTypography';
import ItemTable from "../../UI/Tables/ItemTable";
import AddItemButton from "../../UI/Buttons/AddItemButton";
import DeleteItemButton from "../../UI/Buttons/DeleteItemButton";
import EditItemButton from "../../UI/Buttons/EditItemButton";
import ConfirmationModal from '../../UI/Modal/ConfirmationModal';
import { checkAuthenticationAdmin } from "../../Authentication/checkAuthentication";
import CustomAlert from '../../UI/Alerts/CustomAlert';

const theme = createTheme({
  shape: {
    borderRadius: 5
  },
});

//Format the date to appear properly in table
const gridDateFormatter = (params) => {
  return params.value;
};

//Styling for Items in Grid
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#F9F9F9',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

const BoardAdminPartners = () => {
  const [_content, setContent] = useState("");
  const [rows, setRows] = useState([]);
  const currentUser = useSelector((state) => state.auth.user);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [selectedPartners, setSelectedPartners] = useState(null);
  const [reloadData, setReloadData] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  const [selectedPartnersData, setSelectedPartnersData] = useState([]);

  // Open email client with the selected partners' emails
  const handleOpenEmail = () => {
    // Filter out partners with isAccepted === 1
    const acceptedPartnersEmails = selectedPartnersData
      .filter((partner) => partner.isAccepted === 1)
      .map((partner) => partner.partnerEmail);

    // Check if there are any accepted partners
    if (acceptedPartnersEmails.length > 0) {
      // Create a mailto link with the list of emails
      const mailtoLink = `mailto:${acceptedPartnersEmails.join(',')}`;
      // Open the default email client with the mailto link
      window.location.href = mailtoLink;
    } else {
      handleOpenAlert('error', 'No legit partners selected');
      // You can add a notification or handle the case when no accepted partners are selected
    }
  };

  //Set the columns of the table
  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'partnerName', headerName: 'Name', flex: 1 },
    { field: 'partnerSurname', headerName: 'Surname', flex: 2 },
    {
      field: 'date',
      headerName: 'Date',
      type: 'date',
      width: 200,
      valueFormatter: gridDateFormatter,
    },
    {
      field: 'partnerEmail',
      headerName: "Email",
      width: 200,
    },
    {
      field: 'partnerOrganizationName',
      headerName: "Organization",
      width: 200,
    },
    {
      field: 'isAccepted',
      headerName: 'Accepted Partner',
      width: 200,
      renderCell: (params) => (
        <div>
          {params.value === 0 ? 'No' : 'Yes'}
        </div>
      ),
    }
  ];

    // Function to open the alert
    const handleOpenAlert = (type, message) => {
      setAlertType(type);
      setAlertMessage(message);
      setAlertOpen(true);
    };

  //Get id on row selection in table
  const handleItemTableSelectionChange = (selectedIds) => {
    const selectedRows = rows.filter((row) => selectedIds.includes(row.id.toString()));

    // Update the state with the selected IDs
    setSelectedRowIds(selectedIds);

    //Besides the id also get in a new state variable the info of the selected row/rows
    const selectedPartnersData = selectedIds.map((selectedId) => {
      const selectedPartner = rows.find((partner) => partner.id === selectedId);
      return {
        id: selectedPartner.id,
        partnerEmail: selectedPartner.partnerEmail,
        isAccepted: selectedPartner.isAccepted,
      };
    });
  
    setSelectedPartnersData(selectedPartnersData);
    // Now selectedPartnersData contains an array of objects with id, partnerEmail, and isAccepted
    // You can use selectedPartnersData for further processing or store it in state.
  };

  //Check the authenticity of the user
  useEffect(() => {
    //Call JWT checker each time user switches tabs
    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'visible') {
        try {
          // Check if the user still has an active session/jwt token/cookies or secret key
          const content = await checkAuthenticationAdmin();
          setContent(content);
        } catch (error) {
          const _content = error.message || "Error checking authentication.";
          setContent(_content);
        }
      }
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    //Check anyway for authenticity of the user
    const content = checkAuthenticationAdmin();

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  //Fetch All Partners
  const fetchPartnersData = () => {
    axios.get(API_URL + '/partners/all', {
      params: {
        userRole: currentUser.roles[0],
      },
    })
      .then((response) => {
        const partnersArray = Object.values(response.data).map(partnersItem => ({
          id: partnersItem.id,
          partnerName: partnersItem.partnerName,
          partnerSurname: partnersItem.partnerSurname,
          date: new Date(partnersItem.updatedAt),
          partnerEmail: partnersItem.partnerEmail,
          partnerOrganizationName: partnersItem.partnerOrganizationName,
          partnerComments: partnersItem.partnerComments,
          isAccepted: partnersItem.isAccepted
        }));

        const formattedRows = partnersArray.map(partnersItem => ({
          ...partnersItem,
          date: partnersItem.date.toLocaleDateString('el-GR', { day: 'numeric', month: 'numeric', year: 'numeric' }),
        }));

        setRows(formattedRows);
        // Use the callback form to ensure the correct value of reloadData
        //setReloadData(false); //if something brakes uncomment this line
        setReloadData(prevState => !prevState);
      })
      .catch((error) => {
        console.error('Error fetching partners data:', error);
      });
  };

  //Add Partners Item
  const handleAddPartners = (formData) => {
    // Make axios call to save the form data to the server
    axios.post(`${API_URL}/partners/create`, formData, {
      params: {
        userRole: currentUser.roles[0], // Assuming currentUser is accessible and has roles
        isUserActive: currentUser.isUserActive,
      },
    })
      .then((response) => {
        // Update the state by appending the new item
        setRows(prevRows => [
          ...prevRows,
          {
            id: response.data.partner.id,
            partnerName: response.data.partner.partnerName,
            partnerSurname: response.data.partner.partnerSurname,
            date: new Date(response.data.partner.updatedAt).toLocaleDateString('el-GR', { day: 'numeric', month: 'numeric', year: 'numeric' }),
            partnerEmail: response.data.partner.partnerEmail,
            partnerOrganizationName: response.data.partner.partnerOrganizationName,
            isAccepted: response.data.partner.isAccepted,
          },
        ]);

        handleOpenAlert('success', 'Form Submited successfully!');
      })
      .catch((error) => {
        console.error('Error adding partner:', error);
        handleOpenAlert('error', 'Failed to Submit the form. Please try again.');
      });
  };


  //Get the Id from the selected TableList Entry
  const handleEditPartners = (id = selectedRowIds[0]) => {
    setSelectedPartners(null);
    fetchPartnersById(id);
    setConfirmationModalOpen(false);
  };

  //Fetch Data with specific Partners ID
  const fetchPartnersById = (id) => {
    axios.get(`${API_URL}/partners/${id}`, {
      params: {}
    })
      .then((response) => {
        setSelectedPartners(response.data);
      })
      .catch((error) => {
        console.error('Error fetching partners information:', error);
      });
  };

  // Update Partners Data with the given Partners ID
  const handleUpdateSuccess = (updatedData, id = selectedRowIds[0]) => {
    // Get JWT token from local storage
    const jwtToken = JSON.parse(localStorage.getItem("user")).token;

    const requestData = {
      partnerName: updatedData.name,
      partnerSurname: updatedData.surname,
      partnerEmail: updatedData.email,
      partnerPhone: updatedData.phone,
      partnerOrganizationName: updatedData.organizationName,
      partnerComments: updatedData.comments,
      isAccepted: updatedData.isAccepted,
      userRole: currentUser.roles[0],
      isUserActive: currentUser.isUserActive,
      jwtToken: jwtToken
    };

    axios.post(`${API_URL}/partners/update/${id}`, requestData)
      .then(response => {
        // Handle success
        // Toggle reloadData to force a re-fetch of partners data
        setReloadData(prevState => !prevState);
        fetchPartnersData();
        handleOpenAlert('success', 'Form Submited successfully!');
      })
      .catch(error => {
        // Handle error
        console.error('Update error:', error);
        handleOpenAlert('error', 'Failed to Submit the form. Please try again.');
      });
  };


  //Initialize Modal Delete Partners 
  const handleDeletePartners = () => {
    if (selectedRowIds.length > 0) {
      setConfirmationModalOpen(true);
    } else {
      console.warn('No items selected for deletion');
    }
  };

  //Confirmation and Soft Deletion of the Specific Partners ID's
  const handleConfirmDeletion = () => {
    // Perform the actual deletion when the user confirms

    // Get JWT token from local storage
    const jwtToken = JSON.parse(localStorage.getItem("user")).token;

    axios
      .delete(`${API_URL}/partners/delete`, { data: { ids: selectedRowIds, userRole: currentUser.roles[0], isUserActive: currentUser.isUserActive, jwtToken } })
      .then((response) => {

        // Update the state by removing the deleted items
        setRows((prevRows) => prevRows.filter((item) => !selectedRowIds.includes(item.id)));
        setSelectedRowIds([]); // Reset the selectedRowIds array
        handleOpenAlert('success', 'Data Deleted successfully!');
      })
      .catch((error) => {
        console.error('Error deleting items:', error);
        handleOpenAlert('error', 'Failed to Deleted data. Please try again.');
      });
    setConfirmationModalOpen(false);
  };

  //Cancel Deletion and close Modal
  const handleCancelDeletion = () => {
    // Close the confirmation modal when the user cancels
    setConfirmationModalOpen(false);
  };

  //Update table rows with new data
  useEffect(() => {
    fetchPartnersData();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl">
        <Box sx={{ flexGrow: 1, bgcolor: 'white' }}>
          <ConfirmationModal
            open={confirmationModalOpen}
            onClose={handleCancelDeletion}
            onConfirm={handleConfirmDeletion}
            partnerName="Delete Confirmation"
            content="Are you sure you want to delete these items?"
          />
          <Grid container spacing={2} style={{ paddingTop: '50px' }}>
            <Grid item xs={12}>
              <Item style={{ paddingTop: '30px', paddingBottom: '30px', backgroundImage: 'url("/images/BannerImages/2.jpg")', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <CustomTypography variant="h3" text="Welcome to Admin Page" color="white" />
              </Item>
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ paddingTop: '50px', display: 'flex', flexDirection: 'column' }}>
            <Grid item xs={12}>
              <Item style={{ paddingTop: '30px', paddingBottom: '30px', display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <CustomTypography variant="h4" text="Partners List" color="Teal" />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <AddItemButton onUpdateSuccess={handleAddPartners} buttonTitle={'Add Partners'} isFromBoardAdminPartners={true} modalTitle={'Add Tourist Operations Partners'} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button variant="contained" color="success" onClick={handleOpenEmail}>
                    Send Email to All Partners
                  </Button>
                </div>
              </Item>
            </Grid>
            <Grid item xs={12}>
              <EditItemButton
                onEditItem={() => handleEditPartners(selectedRowIds[0])}
                selectedItems={selectedPartners}
                onUpdateSuccess={handleUpdateSuccess}
                selectedRowIds={selectedRowIds}
                isFromBoardAdminPartners={true}
                modalTitle={'Edit Tourist Operations Partners'}
              />
            </Grid>
            <Grid item xs={12}>
              <ItemTable
                data={rows}
                columns={columns}
                pageSizeOptions={[5, 10, 20, 50]}
                onRowSelectionModelChange={handleItemTableSelectionChange}
              />
            </Grid>
            <Grid item xs={12}>
              <DeleteItemButton buttonTitle='Delete' onDeleteItem={handleDeletePartners} />
            </Grid>
          </Grid>
        </Box>
        <CustomAlert
          open={alertOpen}
          type={alertType}
          message={alertMessage}
          onClose={() => setAlertOpen(false)}
        />
      </Container>
    </ThemeProvider>
  );
};

export default BoardAdminPartners;