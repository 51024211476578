import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

const CustomCheckBox = ({ label, isChecked, onChange, checkboxLabel }) => {
  const handleCheckboxChange = (event) => {
    onChange(event.target.checked); // Call the parent component's onChange with the updated state
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />}
        label={checkboxLabel}
      />
    </FormGroup>
  );
};

export default CustomCheckBox;
