import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';

const ItemTable = ({ data, columns, pageSizeOptions, onRowSelectionModelChange }) => {
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const handleRowSelectionChange = (newSelection) => {
    setSelectedRowIds(newSelection);
    // Invoke the parent callback (onRowSelectionModelChange) with the selected IDs
    onRowSelectionModelChange(newSelection);
  };

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={data}
        columns={columns}
        checkboxSelection
        onRowSelectionModelChange={handleRowSelectionChange}
        selectionModel={selectedRowIds} // Highlight the selected rows
        pageSizeOptions={pageSizeOptions}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: pageSizeOptions[0] || 5 },
          },
        }}
      />
    </div>
  );
};

export default ItemTable;