import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useSelector } from "react-redux";

import Box from '@mui/material/Box';
import Container from "@mui/material/Container";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { API_URL } from '../../../apiConfig';

import CustomTypography from '../../UI/Typography/CustomTypography';
import ItemTable from "../../UI/Tables/ItemTable";
import AddItemButton from "../../UI/Buttons/AddItemButton";
import DeleteItemButton from "../../UI/Buttons/DeleteItemButton";
import EditItemButton from "../../UI/Buttons/EditItemButton";
import ConfirmationModal from '../../UI/Modal/ConfirmationModal';
import { checkAuthenticationAdmin } from "../../Authentication/checkAuthentication";
import CustomAlert from '../../UI/Alerts/CustomAlert';

const theme = createTheme({
  shape: {
    borderRadius: 5
  },
});

//Format the date to appear properly in table
const gridDateFormatter = (params) => {
  return params.value;
};

//Styling for Items in Grid
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#F9F9F9',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

const BoardAdminNews = () => {
  const [_content, setContent] = useState("");
  const [rows, setRows] = useState([]);
  const currentUser = useSelector((state) => state.auth.user);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);
  const [reloadData, setReloadData] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');

  //Set the columns of the table
  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'title', headerName: 'Title', flex: 1 },
    { field: 'shortDescription', headerName: 'Short Description', flex: 2 },
    {
      field: 'date',
      headerName: 'Date',
      type: 'date',
      width: 150,
      valueFormatter: gridDateFormatter,
    },
    {
      field: 'authorName',
      headerName: "Author's Name",
      width: 200,
    }
  ];

  // Function to open the alert
  const handleOpenAlert = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
    setAlertOpen(true);
  };

  //Get id on row selection in table
  const handleItemTableSelectionChange = (selectedIds) => {
    const selectedRows = rows.filter((row) => selectedIds.includes(row.id.toString()));

    // Update the state with the selected IDs
    setSelectedRowIds(selectedIds);
  };

  //Check the authenticity of the user
  useEffect(() => {
    //Call JWT checker each time user switches tabs
    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'visible') {
        try {
          // Check if the user still has an active session/jwt token/cookies or secret key
          const content = await checkAuthenticationAdmin();
          setContent(content);
        } catch (error) {
          const _content = error.message || "Error checking authentication.";
          setContent(_content);
        }
      }
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    //Check anyway for authenticity of the user
    const content = checkAuthenticationAdmin();

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  //Fetch All News
  const fetchNewsData = () => {
    axios.get(API_URL + '/news/all', {
      params: {
        userRole: currentUser.roles[0],
      },
    })
      .then((response) => {
        const newsArray = Object.values(response.data).map(newsItem => ({
          id: newsItem.id,
          title: newsItem.title,
          shortDescription: newsItem.shortDescription,
          date: new Date(newsItem.updatedAt),
          authorName: newsItem.author,
        }));

        const formattedRows = newsArray.map(newsItem => ({
          ...newsItem,
          date: newsItem.date.toLocaleDateString('el-GR', { day: 'numeric', month: 'numeric', year: 'numeric' }),
        }));

        setRows(formattedRows);
        // Use the callback form to ensure the correct value of reloadData
        //setReloadData(false); //if something brakes uncomment this line
        setReloadData(prevState => !prevState);
      })
      .catch((error) => {
        console.error('Error fetching news data:', error);
      });
  };

  //Add News Item
  const handleAddNews = (formData) => {
    const data = new FormData();
    data.append('title', formData.title);
    data.append('shortDescription', formData.shortDescription);
    data.append('longDescription', formData.longDescription);
    data.append('image', formData.image);  // Append the image file directly
    // Add other form data
    data.append('imageURL', formData.imageURL);
    data.append('author', currentUser.username);
    data.append('userId', currentUser.id);
    data.append('userRole', currentUser.roles[0]);
    data.append('isUserActive', currentUser.isUserActive);

    // Get JWT token from local storage
    const jwtToken = JSON.parse(localStorage.getItem("user")).token;
    // Append JWT token to the form data
    data.append('jwtToken', jwtToken);

    axios.post(API_URL + '/news/create', data)
      .then((response) => {
        // Update the date formatting
        const formattedNewsItem = {
          id: response.data.news.id,
          title: response.data.news.title,
          shortDescription: response.data.news.shortDescription,
          date: new Date(response.data.news.createdAt).toLocaleDateString('el-GR', { day: 'numeric', month: 'numeric', year: 'numeric' }),
          authorName: response.data.news.author,
        };

        // Update the state by appending the new item
        setRows((prevRows) => [...prevRows, formattedNewsItem]);
        handleOpenAlert('success', 'Form submitted successfully!');
      })
      .catch((error) => {
        console.error('Error submitting form:', error);
        handleOpenAlert('error', 'Failed to submit the form. Please try again.');
      });
  };

  //Get the Id from the selected TableList Entry
  const handleEditNews = (id = selectedRowIds[0]) => {
    setSelectedNews(null);
    fetchNewsById(id);
  };

  //Fetch Data with specific News ID
  const fetchNewsById = (id) => {
    axios.get(`${API_URL}/news/${id}`, {
      params: {

      }
    })
      .then((response) => {
        setSelectedNews(response.data, id);
      })
      .catch((error) => {
        console.error('Error fetching news information:', error);
      });
  };

  // Update News Data with the given News ID
  const handleUpdateSuccess = (updatedData, id = selectedRowIds[0]) => {
    const data = new FormData();
    data.append('title', updatedData.title);
    data.append('shortDescription', updatedData.shortDescription);
    data.append('longDescription', updatedData.longDescription);
    data.append('image', updatedData.image);  // Append the image file directly
    data.append('imageURL', updatedData.imageURL);

    // Get JWT token from local storage
    const jwtToken = JSON.parse(localStorage.getItem("user")).token;
    // Append JWT token to the form data
    data.append('jwtToken', jwtToken);
    
    axios.post(`${API_URL}/news/update/${id}`, data, {
      params: {
        userRole: currentUser.roles[0],
        isUserActive: currentUser.isUserActive
      },
    })
      .then(response => {
        // Handle success
        // Toggle reloadData to force a re-fetch of news data
        setReloadData(prevState => !prevState);
        fetchNewsData();
        handleOpenAlert('success', 'Form submitted successfully!');
      })
      .catch(error => {
        // Handle error
        console.error('Update error:', error);
        handleOpenAlert('error', 'Failed to submit the form. Please try again.');
      });
  };

  //Initialize Modal Delete News 
  const handleDeleteNews = () => {
    if (selectedRowIds.length > 0) {
      setConfirmationModalOpen(true);
    } else {
      console.warn('No items selected for deletion');
    }
  };

  //Confirmation and Soft Deletion of the Specific News ID's
  const handleConfirmDeletion = () => {
    // Perform the actual deletion when the user confirms

     // Get JWT token from local storage
     const jwtToken = JSON.parse(localStorage.getItem("user")).token;

    axios
      .delete(`${API_URL}/news/delete`, { data: { ids: selectedRowIds, userRole: currentUser.roles[0], isUserActive: currentUser.isUserActive, jwtToken: jwtToken } })
      .then((response) => {

        // Update the state by removing the deleted items
        setRows((prevRows) => prevRows.filter((item) => !selectedRowIds.includes(item.id)));
        setSelectedRowIds([]); // Reset the selectedRowIds array
        handleOpenAlert('success', 'Data deleted successfully!');
      })
      .catch((error) => {
        console.error('Error deleting items:', error);
        handleOpenAlert('error', 'Failed to delete data. Please try again.');
      });
    setConfirmationModalOpen(false);
  };

  //Cancel Deletion and close Modal
  const handleCancelDeletion = () => {
    // Close the confirmation modal when the user cancels
    setConfirmationModalOpen(false);
  };

  //Update table rows with new data
  useEffect(() => {
    fetchNewsData();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl">
        <Box sx={{ flexGrow: 1, bgcolor: 'white' }}>
          <ConfirmationModal
            open={confirmationModalOpen}
            onClose={handleCancelDeletion}
            onConfirm={handleConfirmDeletion}
            title="Delete Confirmation"
            content="Are you sure you want to delete these items?"
          />
          <Grid container spacing={2} style={{ paddingTop: '50px' }}>
            <Grid item xs={12}>
              <Item style={{ paddingTop: '30px', paddingBottom: '30px', backgroundImage: 'url("/images/BannerImages/2.jpg")', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <CustomTypography variant="h3" text="Welcome to Admin Page" color="white" />
              </Item>
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ paddingTop: '50px', display: 'flex', flexDirection: 'column' }}>
            <Grid item xs={12}>
              <Item style={{ paddingTop: '30px', paddingBottom: '30px', display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <CustomTypography variant="h4" text="News List" color="Teal" />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <AddItemButton onAddItem={handleAddNews} buttonTitle={'Add News'} />
                </div>
              </Item>
            </Grid>
            <Grid item xs={12}>

              <EditItemButton
                onEditItem={() => handleEditNews(selectedRowIds[0])}
                selectedItems={selectedNews}
                onUpdateSuccess={handleUpdateSuccess}
                selectedRowIds={selectedRowIds}
              />

            </Grid>
            <Grid item xs={12}>
              <ItemTable
                data={rows}
                columns={columns}
                pageSizeOptions={[5, 10, 20, 50]}
                onRowSelectionModelChange={handleItemTableSelectionChange}
              />
            </Grid>
            <Grid item xs={12}>
              <DeleteItemButton buttonTitle='Delete' onDeleteItem={handleDeleteNews} />
            </Grid>
          </Grid>
        </Box>
        <CustomAlert
          open={alertOpen}
          type={alertType}
          message={alertMessage}
          onClose={() => setAlertOpen(false)}
        />
      </Container>
    </ThemeProvider>
  );
};

export default BoardAdminNews;