// NewsPagination.js
import React from 'react';
import Pagination from '@mui/material/Pagination';

const CustomPager = ({ pageCount, currentPage, onPageChange }) => {
    return (
        <Pagination
            count={pageCount}
            page={currentPage}
            onChange={onPageChange}
        />
    );
};

export default CustomPager;
