import React, { useEffect } from 'react';

const LinkedInWidget = () => {
  useEffect(() => {
    // Load LinkedIn script here
    const script = document.createElement('script');
    script.src = 'https://widgets.sociablekit.com/linkedin-page-posts/widget.js';
    script.async = true;
    script.defer = true; // Ensure the script is deferred
    document.body.appendChild(script);

    return () => {
      // Clean up the script when the component is unmounted
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div style={{ width: '100%', height: '400px', overflowY: 'auto' }}> {/* Apply inline styles */}
      <div className="sk-ww-linkedin-page-post" data-embed-id="25418267"></div>
    </div>
  );
};

export default LinkedInWidget;
