import React from 'react';
import Button from '@mui/material/Button';

const DeleteItemButton = ({ buttonTitle, onDeleteItem }) => {
    const handleDeleteClick = () => {
        // Invoke the parent callback (onDeleteItem) when the button is clicked
        onDeleteItem();
    };

    return (
        <div style={{ paddingBottom: '20px' }}>
            <Button variant="outlined" color="error" onClick={handleDeleteClick}>
                {buttonTitle}
            </Button>
        </div>
    );
};

export default DeleteItemButton;
