import React, { useEffect } from 'react';

const YouTubeWidget = () => {
  useEffect(() => {
    // Load YouTube API script here
    const script = document.createElement('script');
    script.src = 'https://www.youtube.com/iframe_api';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Clean up the script when the component is unmounted
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div style={{ width: '100%', overflow: 'hidden' }}>
      <iframe
        width="100%"
        height="400"
        src="https://www.youtube.com/embed/UA7-iUYl6Nc"
        title="YouTube video player"
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default YouTubeWidget;
