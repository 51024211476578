import React, { useEffect } from 'react';

const TwitterWidget = () => {
  useEffect(() => {
    // Load Twitter script here
    const script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Clean up the script when the component is unmounted
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div style={{ width: '100%', overflow: 'hidden' }}>
      {/* Replace 'realDonaldTrump' with the desired Twitter username */}
      <a
        className="twitter-timeline"
        href="https://twitter.com/Hector_ISF?ref_src=twsrc%5Etfw"
        target="_blank"
        rel="noopener noreferrer"
        data-width="100%"
        data-height="400"
        data-theme="light"
        data-chrome="nofooter noborders"
        data-tweet-limit="3"
      >
        Tweets by Hector_ISF
      </a>
    </div>
  );
};

export default TwitterWidget;
