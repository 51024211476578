import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Parallax } from 'react-parallax';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Grid, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import FadeInSection from './UI/Animations/FadeInSection';
import CustomTypography from './UI/Typography/CustomTypography';
import TwitterWidget from './UI/Widgets/TwitterWidget';
import LinkedInWidget from "./UI/Widgets/LinkedInWidget";

// Define animation properties
const transition = { duration: 0.5, ease: "easeInOut" };
const variants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 }
};

const theme = createTheme({
  shape: {
    borderRadius: 5
  },
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#F9F9F9',
  ...theme.typography.body2,
  padding: theme.spacing(3),
  textAlign: 'center',
  color: theme.palette.text.secondary,

  display: 'flex', // Make it a flex container
  flexDirection: 'column', // Align children in a column
  alignItems: 'center', // Center items horizontally
  justifyContent: 'center', // Center items vertically
}));

const About = () => {
  const [isBodyVisible, setIsBodyVisible] = useState({
    A: false,
    B: false,
    C: false,
    D: false,
    E: false,
    F: false,
    G: false,
    H: false,
    I: false,
    J: false,
    K: false,
    L: false,
    M: false,
    N: false,
    O: false
  });

  const toggleBodyVisibility = (accordion) => {
    setIsBodyVisible((prev) => {
      const newState = { ...prev, [accordion]: !prev[accordion] };

      // Close other accordions when opening one
      Object.keys(newState).forEach((key) => {
        if (key !== accordion) {
          newState[key] = false;
        }
      });

      return newState;
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl">
        <Box sx={{ flexGrow: 1, bgcolor: 'white' }}>
          <Grid container spacing={0}>

            {/* Project Divider Section */}
            <Grid container spacing={2} style={{ paddingBottom: '20px' }}>
              <Grid item xs={12}>
                <Item style={{ paddingTop: '130px', paddingBottom: '230px', backgroundImage: 'url("/images/BannerImages/5.jpg")', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                </Item>
              </Grid>
            </Grid>

            {/* Project Header Section */}
            <Grid container spacing={2} style={{ marginBottom: '50px' }}>
              <Grid item xs={12}>
                <Box textAlign="center">
                  <CustomTypography variant="h3" text="About the Project" color="#0651A0" underlineThickness={3} underline={true} />
                </Box>
              </Grid>
            </Grid>

            {/* Information */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12} xl={12} style={{ marginBottom: '20px' }}>
                <Item style={{ height: '100%' }}>
                  <CustomTypography variant="h5" text="Who We Are" color="#0651A0" />
                  <CustomTypography variant="body1" text="The project’s coordinator is the Hellenic Police in an expanded form of consortium (18 partners – 5 countries), with the participation of law enforcement agencies, research institutions, municipal authorities, and tourism operators." color="black" />
                  <CustomTypography variant="body1" text="The HECTOR project aims to shield European tourism infrastructure, emphasizing strengthening cooperation between the private and public sectors to define security standards, procedures, and protocols (Security Model Plan for Tourism Industry)." color="black" />
                  <br></br>
                  <CustomTypography variant="h5" text="About Tourism" color="#0651A0" />
                  <CustomTypography variant="body1" text="Tourism is a significant contributor to the economic growth and cultural preservation of Europe, with over 700 million tourists visiting the continent every year. However, the popularity of tourist sites also makes them vulnerable to terrorist activities, due to high visitor numbers, accessibility, and often inadequate security measures." color="black" />
                  <br></br>
                  <CustomTypography variant="h5" text="Action" color="#0651A0" />
                  <CustomTypography variant="body1" text="To address these challenges, the HECTOR project has been launched, which aims to establish a comprehensive approach to protecting tourism infrastructure across Europe. This project emphasizes collaboration between the private and public sectors, integrating standard operating procedures (SOPs), recommendations, guidelines, tools, and best practices to enhance safety and security." color="black" />
                  <CustomTypography variant="body1" text="The project is named after HECTOR Project, who was known for his commitment to protecting his community during the Trojan War. It reflects the EU's dedication to securing the tourism sector and the urgent need to preserve freedom and democracy in the face of modern threats." color="black" />
                </Item>
              </Grid>
            </Grid>

            {/* Our Team Section */}
            {/* <Grid container spacing={2}>
              <Grid item xs={12}>
                <Item style={{ marginTop: "60px" }}>
                  <CustomTypography variant="h5" text="Our Team" color="#0651A0" />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Paper elevation={3} style={{ padding: 10, borderRadius: 10 }}>
                        <img src="images/Members/1.png" alt="Team Member" style={{ width: '40%', borderRadius: '50%' }} />
                        <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginTop: 10 }}>
                          Jane Doe
                        </Typography>
                        <Typography>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Paper elevation={3} style={{ padding: 10, borderRadius: 10 }}>
                        <img src="images/Members/1.png" alt="Team Member" style={{ width: '40%', borderRadius: '50%' }} />
                        <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginTop: 10 }}>
                          Jane Doe
                        </Typography>
                        <Typography>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Paper elevation={3} style={{ padding: 10, borderRadius: 10 }}>
                        <img src="images/Members/1.png" alt="Team Member" style={{ width: '40%', borderRadius: '50%' }} />
                        <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginTop: 10 }}>
                          Jane Doe
                        </Typography>
                        <Typography>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Paper elevation={3} style={{ padding: 10, borderRadius: 10 }}>
                        <img src="images/Members/1.png" alt="Team Member" style={{ width: '40%', borderRadius: '50%' }} />
                        <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginTop: 10 }}>
                          Jane Doe
                        </Typography>
                        <Typography>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </Item>
              </Grid>
            </Grid> */}

            {/* What We Do Divider*/}
            <Grid container spacing={2} style={{ paddingTop: '65px' }}>
              <Grid item xs={12}>
                <Item style={{ paddingTop: '130px', paddingBottom: '230px', backgroundImage: 'url("/images/BannerImages/6.jpg")', backgroundSize: 'cover', backgroundPosition: '50% 70%' }}>
                </Item>
              </Grid>
            </Grid>

            {/* Project Header Section */}
            <Grid container spacing={2} style={{ marginBottom: '25px', marginTop: '25px' }}>
              <Grid item xs={12}>
                <Box textAlign="center">
                  <CustomTypography variant="h3" text="What We Do" color="#0651A0" underlineThickness={3} underline={true} />
                </Box>
              </Grid>
            </Grid>

            {/* Program Aim & Outcome */}
            <Grid container spacing={2} style={{ paddingTop: '25px', paddingBottom: '65px' }}>
              <Grid item xs={12}>
                <Item style={{ height: '100%' }}>
                  <CustomTypography variant="h5" text="Program Aim & Outcome" color="#0651A0" />
                  <br></br>
                  <CustomTypography variant="body1" text="HECTOR Project is set to bring significant security enhancements to tourist sites throughout Europe. These upgrades will include fortified physical protection and cutting-edge security technologies, ultimately reducing vulnerabilities and improving tourist security. With these robust security measures, travelers will soon be able to explore with renewed confidence and peace of mind." color="black" />
                  <br></br>
                  <CustomTypography variant="body1" text="HECTOR Project will also take a collaborative approach to empowering stakeholders within the tourism industry in the years to come. Law enforcement agencies, local municipalities, universities, private companies, and communities will work together to implement state-of-the-art security best practices. Specialized training programs featuring modern technologies, such as VR modules and e-learning platforms, will equip staff and tourists with the knowledge and skills to respond effectively to security threats. These collective efforts will create a safer and more resilient environment for tourism, perfectly aligned with the EU's priorities for protecting public spaces in the future." color="black" />
                  <br></br>
                  <CustomTypography variant="body1" text="The project is a flagship initiative in protecting public spaces based on the relevant EU action plan. [COM(2017) 612 final], while innovatively incorporating the corresponding “good practices” of [SWD(2019) 140 final]." color="black" />
                  <br></br>
                  <CustomTypography variant="body1" text="The methodology to be followed by the program has been inspired by the Commission’s European Counter-Terrorism Agenda, with risk assessments of tourist sites in the EU (anticipate), community involvement with a bottom-up approach (prevent), physical protection and a security planning-based approach (protect) and strengthening cooperation at all levels (respond)." color="black" />
                  <br></br>
                  <CustomTypography variant="body1" text="Through this project, the first axis of the EU Security Union Strategy, a secure security environment for the future, is achieved, as tourism is an area of interest for every member state." color="black" />
                </Item>
              </Grid>

              {/* A SECTION */}
              <Grid container spacing={2} style={{ marginBottom: '25px', marginTop: '25px' }}>
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <CustomTypography variant="h3" text="Phase A: Security Planning" color="#0651A0" underlineThickness={3} underline={true} />
                  </Box>
                </Grid>
              </Grid>

              {/* Phase A */}
              <Grid item xs={12} md={12} lg={6} xl={6} style={{ marginBottom: '20px', marginTop: '20px' }}>
                <FadeInSection>
                  <CustomTypography variant="body1" text="As part of the HECTOR project, vulnerability assessments on various tourist sites will be conducted to evaluate potential security threats. The assessments will cover all types of tourist activities and will consider past attacks. The chosen locations for vulnerability assessments include Athens International Airport, Port of Thessaloniki, Lyon's Old Town, Mykonos Island, Varna City, Jewish Museum and Holocaust Site, Limassol Marina, Louis Group - Apollonia, the archaeological site surrounding the Athens Acropolis area, and Krakow Square." color="black" />
                </FadeInSection>
                <br />
                <FadeInSection>
                  <CustomTypography variant="body1" text="The HECTOR project's Security Risk Assessment Group (SRAG) training initiative aims to equip relevant stakeholders across Europe with advanced skills and comprehensive knowledge necessary to tackle different security challenges and especially in security planning. The training will range from routine security & safety operations to complex terrorist threats. The goal is to foster a unified and highly effective response mechanism to ensure rapid, coordinated, and efficient actions in times of crisis. This will be the core group to implement the above mentioned VAs" color="black" />
                </FadeInSection>
                <br />
                <FadeInSection>
                  <CustomTypography variant="body1" text="HECTOR project will introduce a Tailor-Made Vulnerability Assessment (VA) Checklist for the tourism industry. This checklist is specifically designed to cater to the tourism sector's unique security challenges and needs. It aims to provide stakeholders with a comprehensive tool for identifying and addressing potential vulnerabilities proactively. The checklist will be customized to cater to the diverse range of environments within the industry, ensuring that all aspects of tourist safety are thoroughly evaluated and fortified." color="black" />
                </FadeInSection>
              </Grid>

              {/* Phase A Divider */}
              <Grid item xs={12} md={12} lg={6} xl={6} style={{ marginBottom: '20px', marginTop: '20px' }}>
                <FadeInSection>
                  <Item style={{ paddingTop: '250px', paddingBottom: '250px', backgroundImage: 'url("/images/AboutImages/1.jpg")', backgroundPosition: 'center', backgroundSize: 'cover' }}></Item>
                </FadeInSection>
              </Grid>

              {/* B Section */}
              <Grid container spacing={2} style={{ marginBottom: '25px', marginTop: '25px' }}>
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <CustomTypography variant="h3" text="Phase B: Physical security and technological solutions" color="#0651A0" underlineThickness={3} underline={true} />
                  </Box>
                </Grid>
              </Grid>

              {/* Phase B Divider */}
              <Grid item xs={12} md={12} lg={6} xl={6} style={{ marginBottom: '20px', marginTop: '20px' }}>
                <FadeInSection>
                  <Item style={{ paddingTop: '250px', paddingBottom: '250px', backgroundImage: 'url("/images/AboutImages/2.jpg")', backgroundPosition: 'center', backgroundSize: 'cover' }}></Item>
                </FadeInSection>
              </Grid>

              {/* Phase B */}
              <Grid item xs={12} md={12} lg={6} xl={6} style={{ marginBottom: '20px', marginTop: '20px' }}>
                <FadeInSection>
                  <CustomTypography variant="body1" text="During the security planning process, the HECTOR project evaluates the physical protection of premises and examines innovative technological solutions that can be implemented for enhanced safety and security. The project includes several initiatives aimed at improving security in the tourism sector, by implementing tailor made security measures, both in physical security and technological measures." color="black" />
                </FadeInSection>
                <br />
                <FadeInSection>
                  <CustomTypography variant="body1" text="The HECTOR project has introduced several initiatives to enhance security measures at tourist sites. One of the initiatives is the Critical Incidents Observatory (CIO), which collects and analyzes data on security incidents – terrorist attacks at tourist sites. The CIO aims to develop a thorough understanding of potential threats and create customized Standard Operating Procedures and recommendations. This ensures that public space operators and security personnel are well-equipped to manage emergencies." color="black" />
                </FadeInSection>
                <br />
                <FadeInSection>
                  <CustomTypography variant="body1" text="Another initiative is the Security by Design program, which integrates security considerations into the development and management of tourist sites. The program categorizes tourist sites in line with EU guidelines and principles and creates a handbook for tourism operators to follow." color="black" />
                </FadeInSection>
                <br />
                <FadeInSection>
                  <CustomTypography variant="body1" text="The projects technical experts will conduct a comprehensive Systematic Literature Review (SLR) to gather the latest security best practices and technological innovations. The insights from the SLR are instrumental in crafting a detailed handbook for EU tourism operators, guiding them towards preemptive security planning and implementation." color="black" />
                </FadeInSection>
              </Grid>

              {/* Phase B */}
              <Grid item xs={12} md={12} lg={6} xl={6} style={{ marginBottom: '20px', marginTop: '20px' }}>
                <FadeInSection>
                  <CustomTypography variant="body1" text="Capitalizing the previous results, HECTOR project will set up and deploy Mobile Operational Command Posts to enhance public events security. These units aim to bolster security at major public events, providing a nimble and highly responsive command center that can adapt to the dynamic needs of large gatherings." color="black" />
                </FadeInSection>
                <br />
                <FadeInSection>
                  <CustomTypography variant="body1" text="This initiative highlights the HECTOR project's commitment to ensuring the safety and security of tourists and residents during high-profile events. It also demonstrates a proactive approach to adapting to and mitigating potential security challenges in real time. The HECTOR project is dedicated to fostering a safer environment for all by implementing comprehensive and innovative security measures in the tourism sector." color="black" />
                </FadeInSection>
                <br />
                <FadeInSection>
                  <CustomTypography variant="body1" text="The project, during the first two phases, will focus on Risk Analysis & Security Model Plan of the EU Tourism Landscape (WP 2), through Standard Operating Procedures (SOPs), recommendations, security and crisis management plans, and security by design and physical security guidelines. These efforts will align with European guidelines on protecting public spaces, culminating in the creation of a Security Model Plan for European tourist sites and infrastructures. Vulnerability assessments of tourist sites will be carried out – covering the entire spectrum of tourist activities – while the results will be used, considering past attacks, to evaluate threats in security planning." color="black" />
                </FadeInSection>
              </Grid>

              {/* Phase B Divider */}
              <Grid item xs={12} md={12} lg={6} xl={6} style={{ marginBottom: '20px', marginTop: '20px' }}>
                <FadeInSection>
                  <Item style={{ paddingTop: '250px', paddingBottom: '250px', backgroundImage: 'url("/images/AboutImages/3.jpg")', backgroundPosition: 'center', backgroundSize: 'cover' }}></Item>
                </FadeInSection>
              </Grid>

              {/* C Section */}
              <Grid container spacing={2} style={{ marginBottom: '25px', marginTop: '25px' }}>
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <CustomTypography variant="h3" text="Phase C: Awareness and Education" color="#0651A0" underlineThickness={3} underline={true} />
                  </Box>
                </Grid>
              </Grid>

              {/* Phase C Divider */}
              <Grid item xs={12} md={12} lg={6} xl={6} style={{ marginBottom: '20px', marginTop: '20px' }}>
                <FadeInSection>
                  <Item style={{ paddingTop: '250px', paddingBottom: '250px', backgroundImage: 'url("/images/AboutImages/4.jpg")', backgroundPosition: 'center', backgroundSize: 'cover' }}></Item>
                </FadeInSection>
              </Grid>

              {/* Phase C */}
              <Grid item xs={12} md={12} lg={6} xl={6} style={{ marginBottom: '20px', marginTop: '20px' }}>
                <FadeInSection>
                  <CustomTypography variant="body1" text="Additionally, HECTOR Project seeks to elevate the security awareness and culture of both tourism operators and tourists via a comprehensive European campaign and a Joint Training Programme based on the previous mentioned contemporary Security Model Plan. The effectiveness of the actions will be tested through a large scale exercise on the final phase of the project, building synergies for the Enhancement of Public – Private Cooperation (WP 3)." color="black" />
                </FadeInSection>
                <br />
                <FadeInSection>
                  <CustomTypography variant="body1" text="The HECTOR project is launching a European Raise Awareness Campaign, an essential effort to enhance the security of public tourist spots by increasing awareness and providing educational content, through videos, posters, newsletters, podcasts and other digital material. This campaign targets a broad audience, including tourism operators, security professionals, policymakers, tourists, and the general public, with the objective of disseminating knowledge and exchanging best practices to promote safer tourism settings throughout Europe." color="black" />
                </FadeInSection>
                <br />
                <FadeInSection>
                  <CustomTypography variant="body1" text="The campaign, will be carried out by the Community Engagement Hubs (CE Hubs) representing a novel approach to strengthening the security framework within the European tourism sector. These hubs will create a core of key stakeholders to promote a culture of preparedness and awareness, establishing a coordination group for the Raise Awareness Campaign. The CE Hubs are designed to facilitate the exchange of best practices, improve communication skills, and provide comprehensive security training, aiming to foster a proactive and cohesive community capable of addressing and mitigating security threats effectively, giving the opportunity also to create a security tourism network. The HECTOR project, through the European Raise Awareness Campaign, is committed to enhancing the safety and security of public tourist spaces by educating and engaging communities across Europe. The campaign will leverage an array of communication materials and digital tools to spread its message far and wide." color="black" />
                </FadeInSection>
              </Grid>

              {/* Phase C */}
              <Grid item xs={12} md={12} lg={6} xl={6} style={{ marginBottom: '20px', marginTop: '20px' }}>
                <FadeInSection>
                  <CustomTypography variant="body1" text="Additionally, the project plans to involve citizens directly through the safEU Platform, an application for mobile phones, enabling their active participation in safety and security initiatives. This comprehensive approach signifies a significant advancement towards a more resilient and secure tourism industry in Europe." color="black" />
                </FadeInSection>
                <br />
                <FadeInSection>
                  <CustomTypography variant="body1" text="Moreover, an innovative Joint Training Program for the Tourism Industry is on the horizon focusing to deliver knowledge to more than 900 participants. This program is designed to strengthen the resilience and security of European tourist destinations, involving a varied group of participants from municipalities, law enforcement, private security firms, tourism operators, and tour guides. This collaboration aims to build a mutual understanding and shared knowledge among all involved parties, ensuring a comprehensive grasp of roles and response procedures." color="black" />
                </FadeInSection>
                <br />
                <FadeInSection>
                  <CustomTypography variant="body1" text="The training curriculums will address key security challenges within the tourism sector, covering topics like managing C.B.R.N.E. threats in public spaces, countering non cooperative U.A.V.s, understanding the responsibilities of Security Managers, enhancing security awareness among tourism industry staff, identifying and mitigating insider threats, and developing effective crisis response and recovery plans. Expert consultations will inform the training content and materials, ensuring they are both relevant and impactful." color="black" />
                </FadeInSection>
                <br />
                <FadeInSection>
                  <CustomTypography variant="body1" text="Training Centres equipped with state-of-the-art technology, will be established in participant countries. These centers will support in-person training and provide access to an e-learning platform, which will offer a wide range of multimedia training content. The platform will include VR Modules for game-based simulations, using data from the Critical Incidents Observatory (C.I.O.) to create realistic 3D scenarios, allowing participants to practice responding to various security threats." color="black" />
                </FadeInSection>
              </Grid>

              {/* Phase C Divider */}
              <Grid item xs={12} md={12} lg={6} xl={6} style={{ marginBottom: '20px', marginTop: '20px' }}>
                <FadeInSection>
                  <Item style={{ paddingTop: '250px', paddingBottom: '250px', backgroundImage: 'url("/images/AboutImages/5.jpg")', backgroundPosition: 'center', backgroundSize: 'cover' }}></Item>
                </FadeInSection>
              </Grid>

              {/* D Section */}
              <Grid container spacing={2} style={{ marginBottom: '25px', marginTop: '25px' }}>
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <CustomTypography variant="h3" text="Phase D: Interoperability and Collaboration" color="#0651A0" underlineThickness={3} underline={true} />
                  </Box>
                </Grid>
              </Grid>

              {/* Phase D */}
              <Grid item xs={12} md={12} lg={12} xl={12} style={{ marginTop: '20px' }}>
                <CustomTypography variant="body1" text="Finally, the project will create a standard security model for tourist sites, providing procedures, recommendations, good practices, and guidelines. The model’s effectiveness will be tested through a cross-border counter terrorism exercise, evolving during different phases, critical events in five countries. The exercise scenario will essentially cause a theater of operations, with the development of critical incidents that require a high level of cooperation (private and public entities) and interoperability (between countries)." color="black" />
              </Grid>
            </Grid>

            {/* Q & A Divider*/}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Item style={{ paddingTop: '130px', paddingBottom: '230px', backgroundImage: 'url("/images/AboutImages/6.jpg")', backgroundPosition: 'center', backgroundSize: 'cover' }}>
                </Item>
              </Grid>
            </Grid>

            {/* Questions & Answers about the HECTOR */}
            <Grid container spacing={2} style={{ paddingTop: '25px' }}>
              <Grid item xs={12} md={12} lg={12} xl={12} style={{ marginBottom: '20px' }}>
                <Item style={{ height: '100%' }}>

                  <CustomTypography variant="h5" text="10+1 Questions & Answers about the HECTOR project to enhance tourism security" color="#0651A0" />

                  <Accordion expanded={isBodyVisible.E} onChange={() => toggleBodyVisibility('E')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <CustomTypography variant="body1" text="1. What is the ultimate goal of the HECTOR project?" color="#0651A0" />
                    </AccordionSummary>
                    <AccordionDetails>
                      <CustomTypography variant="body1" text="A: The HECTOR project concerns the security of tourist destinations in Europe. It is about dealing with immediate risks and threats and formulating a long-term security strategy. The aim is to shield 'soft' targets. Hence, they are safer for visitors using a combination of advanced assessments, cross-sector collaboration, and sustainable security practices." color="black" />
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={isBodyVisible.F} onChange={() => toggleBodyVisibility('F')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <CustomTypography variant="body1" text="2. How does the HECTOR project fulfill the EU strategies? For protecting public spaces, especially in the context of tourist security?" color="#0651A0" />
                    </AccordionSummary>
                    <AccordionDetails>
                      <CustomTypography variant="body1" text="A: The HECTOR project is a unique initiative perfectly aligned with the EU action plan. For the protection of public spaces, as described in COM (2017) 612 Final, incorporating also good practices from SWD (2019) 140 Final. In addition, HECTOR's methodology is inspired by the European Counter-Terrorism Agenda through a holistic approach, which includes predicting risks and threats to tourist locations across the EU, encouraging community participation, implementing safeguards, and strengthening cooperation at all levels." color="black" />
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={isBodyVisible.G} onChange={() => toggleBodyVisibility('G')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <CustomTypography variant="body1" text="3. Who participates in HECTOR, and why does it matter?" color="#0651A0" />
                    </AccordionSummary>
                    <AccordionDetails>
                      <CustomTypography variant="body1" text="A: HECTOR consists of a pool of experts drawn from law enforcement, research centers, policymakers, and people who manage tourist spots and crowds. The participation of experts from multiple agencies marks the multi-prismatic and global view of the security of tourist sites – from prevention to response and from roads to policy documents." color="black" />
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={isBodyVisible.H} onChange={() => toggleBodyVisibility('H')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <CustomTypography variant="body1" text="4. How will HECTOR deal with security in tourist areas?" color="#0651A0" />
                    </AccordionSummary>
                    <AccordionDetails>
                      <CustomTypography variant="body1" text="A.: HECTOR's strategy is diverse and multi-layered. As part of this, specialized risk assessment groups (SRAGs) and incident observation are being created. These teams will examine potential threats from various angles and develop customized security measures for different locations." color="black" />
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={isBodyVisible.I} onChange={() => toggleBodyVisibility('I')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <CustomTypography variant="body1" text="5. What is the Critical Incident Observatory at HECTOR?" color="#0651A0" />
                    </AccordionSummary>
                    <AccordionDetails>
                      <CustomTypography variant="body1" text="A: Think of it as HECTOR's Intelligence Center. The Observatory will analyze security threats, develop guidelines and procedures, and most importantly, act as the brains of the Operational sector, figuring out the best ways to keep tourist spots safe." color="black" />
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={isBodyVisible.J} onChange={() => toggleBodyVisibility('J')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <CustomTypography variant="body1" text="6. What is the 'safEU' app?" color="#0651A0" />
                    </AccordionSummary>
                    <AccordionDetails>
                      <CustomTypography variant="body1" text="A: safEU or Safe Europe is HECTOR's digital assistant. It's an app that connects users directly to authorities in an emergency and provides real-time safety advice. It is about giving tourists and locals the information they need when they need it." color="black" />
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={isBodyVisible.K} onChange={() => toggleBodyVisibility('K')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <CustomTypography variant="body1" text="7. How does HECTOR plan to maintain its momentum?" color="#0651A0" />
                    </AccordionSummary>
                    <AccordionDetails>
                      <CustomTypography variant="body1" text="A: HECTOR expects to maintain the involvement of the consortium's stakeholders and ensure that its strategies and results are continuously evolving, linking the project to wider EU efforts so that it has a lasting impact on the European community." color="black" />
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={isBodyVisible.L} onChange={() => toggleBodyVisibility('L')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <CustomTypography variant="body1" text="8. How will HECTOR address the diversity of tourist sites?" color="#0651A0" />
                    </AccordionSummary>
                    <AccordionDetails>
                      <CustomTypography variant="body1" text="A: One-size-fits-all doesn't work here. The HECTOR consortium knows that each tourist spot has its own atmosphere and its own particular risks. Thus, they tailor security measures on a case-by-case basis, ensuring that each party gets their share to stay safe." color="black" />
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={isBodyVisible.M} onChange={() => toggleBodyVisibility('M')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <CustomTypography variant="body1" text="9. What is HECTOR's plan for training tourism staff?" color="#0651A0" />
                    </AccordionSummary>
                    <AccordionDetails>
                      <CustomTypography variant="body1" text="A: HECTOR focuses on pre-event preparation and prevention. Through the project, programs are launched to teach and sensitize tourism staff on security, crisis management, and even the basics of counter-terrorism. The idea is to make sure people are ready for anything." color="black" />
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={isBodyVisible.N} onChange={() => toggleBodyVisibility('N')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <CustomTypography variant="body1" text="10. How does HECTOR make a difference?" color="#0651A0" />
                    </AccordionSummary>
                    <AccordionDetails>
                      <CustomTypography variant="body1" text="A: HECTOR sets clear milestones to track its progress. In its course, the operation and efficiency of the security measures are examined, how the stakeholders feel about the changes and how this affects the overall security of the tourist spots." color="black" />
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={isBodyVisible.O} onChange={() => toggleBodyVisibility('O')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <CustomTypography variant="body1" text="11. How will HECTOR strengthen European Cooperation?" color="#0651A0" />
                    </AccordionSummary>
                    <AccordionDetails>
                      <CustomTypography variant="body1" text="A: HECTOR will exchange best practices, experiences, and lessons learned with a network of stakeholders across Europe, aiming to create a strong community where everyone works together for a common and universal goal: safer tourism." color="black" />
                    </AccordionDetails>
                  </Accordion>
                </Item>
              </Grid>

              {/* Twitter Widget Section */}
              <Grid item xs={12} md={12} lg={6} xl={6} style={{ marginBottom: '20px' }}>
                <Item>
                  <TwitterWidget />
                </Item>
              </Grid>

              {/* LinkedIn Widget Section */}
              <Grid item xs={12} md={6} lg={6} xl={6} style={{ marginBottom: '20px' }}>
                <Item>
                  <LinkedInWidget />
                </Item>
              </Grid>

            </Grid>

            {/* Footer Section */}
            <Grid container spacing={2} style={{ paddingTop: '50px' }}>
              <Grid item xs={12} sm={12} md={12}>
                <Item style={{ paddingTop: '50px', paddingBottom: '50px', backgroundImage: 'url("/images/BannerImages/footer.jpg")', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                  <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" padding="20px">
                    <Box display="flex" alignItems="center">
                      <img src="/images/eu-flag.png" alt="European Union Flag" style={{ width: '100px', height: 'auto', marginRight: '10px' }} />
                    </Box>
                    <Box display="flex" flexDirection="column" alignItems="flex-start" flex="1">
                      <CustomTypography variant="h7" text="This project is funded by the European Union’s Internal Security Fund — Police." color="white" />
                    </Box>
                    <Box display="flex" flexDirection="column" alignItems="center" flex="1">
                      <CustomTypography variant="h7" text="Grant Agreement No. 101100558 — PROJECT HECTOR" color="white" />
                    </Box>
                    <Box display="flex" flexDirection="column" alignItems="flex-end" flex="1">
                      <CustomTypography variant="h7" text="The content of this webpage represents the views of the author only and is his/her sole responsibility. The European Commission does not accept any responsibility for use that may be made of the information it contains." color="white" />
                    </Box>
                  </Box>
                </Item>
              </Grid>
            </Grid>

          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default About;
