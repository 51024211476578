import React from 'react';
import Slider from 'react-slick';
import Box from '@mui/material/Box';

const BannerCarousel = ({ images }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000
  };

  return (
    <Slider {...settings}>
      {images.map((src, index) => (
        <Box key={index} sx={{ width: '100%', height: '800px', backgroundImage: `url(${src})`, backgroundPosition: 'center' }} />
      ))}
    </Slider>
  );
};

export default BannerCarousel;
