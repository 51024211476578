import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Grid, Typography } from '@mui/material';
import CustomCheckBox from '../CheckBoxes/CustomCheckBox';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { debounce } from 'lodash';

const ItemForm = ({ formData, setFormData, onFormSubmit, onFormUpdate, onCancel, selectedItems, onUpdateSuccess, isFromBoardAdminEvents, isFromBoardAdminActions }) => {
  const [titleError, setTitleError] = useState(false);
  const [shortDescriptionError, setShortDescriptionError] = useState(false);
  const [longDescriptionError, setLongDescriptionError] = useState(false);
  const [isEventOpen, setIsEventOpen] = useState(false);
  const [imageUploadSuccess, setImageUploadSuccess] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [initialValuesSet, setInitialValuesSet] = useState(false);

  useEffect(() => {
    setTitleError(formData.title === '');
    setShortDescriptionError(formData.shortDescription === '');
    setLongDescriptionError(formData.longDescription === '');
    setIsEventOpen(formData.isEventOpen === false);

    if (selectedItems && !initialValuesSet) {
      if (selectedItems !== formData) {
        setFormData({
          title: selectedItems.title || '',
          shortDescription: selectedItems.shortDescription || '',
          longDescription: selectedItems.longDescription || '',
          isEventOpen: selectedItems.isEventOpen || false,
          image: selectedItems.image || null,
          imageURL: selectedItems.imageURL || null,
          pdf: selectedItems.pdf || null,
          pdfURL: selectedItems.pdfURL || null,
        });
  
        // If an imageURL is available, set it as the preview
        if (selectedItems.imageURL) {
          setImageUploadSuccess(true);
          setImagePreview(selectedItems.imageURL);
        }
        // If a pdfURL is available, set it as the preview
        if (selectedItems.pdfURL) {
          setImageUploadSuccess(true);
          setImagePreview(selectedItems.pdfURL);
        }
  
        setInitialValuesSet(true);
      }
    }
  }, [formData, selectedItems, setFormData, initialValuesSet]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  //Concatenate HTML tags leftovers from Quill and remove them from textfield state
  const isQuillEmpty = (value) => {
    return value.replace(/<(.|\n)*?>/g, '').trim().length === 0 && !value.includes("<img");
  };

  const handleChangeLongDescription = debounce((value) => {
    // Check if Quill content is considered empty
    const isEmpty = isQuillEmpty(value);

    // Set longDescription to an empty string if Quill content is empty, otherwise set it to the value
    setFormData((prevData) => ({
      ...prevData,
      longDescription: isEmpty ? '' : value,
    }));
  }, 700); // Adjust the debounce delay as needed

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    // Check if a file is selected
    if (file) {
      // Update form data with the image file and imageURL
      setFormData((prevData) => {
        const newFormData = {
          ...prevData,
          image: file,
          // Assuming you have an endpoint for serving images, construct the URL here
          imageURL: URL.createObjectURL(file),
        };

        // Simulating image upload success
        setImageUploadSuccess(true);

        // Generate a preview URL for the uploaded image
        const previewURL = URL.createObjectURL(file);
        setImagePreview(previewURL);

        return newFormData;
      });
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
  
    // Check if a file is selected
    if (file) {
      // Update form data with the file and fileURL
      setFormData((prevData) => {
        const newFormData = {
          ...prevData,
          pdf: file,  // This is the correct property name for the file
          pdfURL: URL.createObjectURL(file),
        };
  
        // Simulating file upload success
        setImageUploadSuccess(true);
  
        // Generate a preview URL for the uploaded file (PDF)
        const previewURL = URL.createObjectURL(file);
        setImagePreview(previewURL);
  
        return newFormData;
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    // Check if all mandatory fields are filled
    if (formData.title === '' || formData.shortDescription === '' || formData.longDescription === '') {
      console.error('Please fill in all mandatory fields');
      return;
    }

    try {
      if (selectedItems) {
        // Update existing entry
        onFormUpdate(formData);
        // Execute the onUpdateSuccess callback with the updated data
        onUpdateSuccess(formData);
      } else {
        // Create new entry
        onFormSubmit(formData);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };


  const handleCancel = () => {
    onCancel();
  };

  return (
    <Container maxWidth="md">
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Title"
              variant="outlined"
              name="title"
              value={formData.title}
              onChange={handleChange}
              error={titleError}
              helperText={titleError && 'Title is required'}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Short Description"
              variant="outlined"
              name="shortDescription"
              value={formData.shortDescription}
              onChange={handleChange}
              error={shortDescriptionError}
              helperText={shortDescriptionError && 'Short Description is required'}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <ReactQuill
              minRows={4}
              maxRows={10}
              placeholder="Long Description"
              style={{
                width: '100%',
                padding: '8px',
                marginTop: '8px',
                overflowY: 'auto',
                maxHeight: '300px', // Adjust the maxHeight to your desired height
              }}
              theme="snow"
              name="longDescription"
              value={formData.longDescription}
              onChange={handleChangeLongDescription}
              error={longDescriptionError}
              required
            />
            {longDescriptionError && (
              <Typography variant="body2" color="error">
                Long Description is required
              </Typography>
            )}
          </Grid>

          {isFromBoardAdminEvents && (
            <Grid item xs={6}>
              <CustomCheckBox
                checkboxLabel="This is an Open Event"
                isChecked={formData.isEventOpen || false}
                onChange={(isChecked) => setFormData({ ...formData, isEventOpen: isChecked })}
              />
            </Grid>
          )}

          {isFromBoardAdminActions ? (
            // Render PDF uploader for actions
            <Grid item xs={12}>
              <input
                accept=".pdf"
                style={{ display: 'none' }}
                id="file-upload"
                type="file"
                onChange={handleFileChange}
              />
              <label htmlFor="file-upload">
                <Button variant="contained" color="secondary" component="span">
                  Upload PDF
                </Button>
              </label>
              {imageUploadSuccess && (
                <Typography variant="body2" color="success">
                  PDF uploaded successfully!
                </Typography>
              )}
              {imagePreview && (
                <embed
                  src={imagePreview}
                  type="application/pdf"
                  style={{
                    width: '100%',
                    height: '500px',
                    marginTop: '8px',
                  }}
                />
              )}
            </Grid>
          ) : (
            // Render image uploader for news and events
            <Grid item xs={12}>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="image-upload"
                type="file"
                onChange={handleImageChange}
              />
              <label htmlFor="image-upload">
                <Button variant="contained" color="secondary" component="span">
                  Upload Image
                </Button>
              </label>
              {imageUploadSuccess && (
                <Typography variant="body2" color="success">
                  Image uploaded successfully!
                </Typography>
              )}
              {imagePreview && (
                <img
                  src={imagePreview}
                  alt="Image Preview"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '150px',
                    marginTop: '8px',
                  }}
                />
              )}
            </Grid>
          )}
          <Grid item xs={6}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={titleError || shortDescriptionError || longDescriptionError}
            >
              Submit
            </Button>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Button
              variant="outlined"
              color="error"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default ItemForm;