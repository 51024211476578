import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const TypographyItem = ({ variant, text, color, underlineThickness, underline }) => (
  <Typography variant={variant} gutterBottom style={{ color: color, borderBottom: underline ? `3px solid #FFA500` : 'none', paddingBottom: '5px', width: 'fit-content', margin: '0 auto' }}>
    {text}
  </Typography>
);

const CustomTypography = ({ variant, text, color, underlineThickness, underline }) => (
  <Box sx={{ width: '100%'}}>
    <TypographyItem variant={variant} text={text} color={color} underlineThickness={underlineThickness} underline={underline} />
  </Box>
);

export default CustomTypography;
