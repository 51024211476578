import React from "react";
import { Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import { deepPurple } from '@mui/material/colors';

const getRoleDisplayName = (role) => {
  // Map roles to more user-friendly names
  const roleMap = {
    ROLE_ADMIN: 'Administrator',
    ROLE_USER: 'User', // Add more roles as needed
  };

  return roleMap[role] || role; // Return the mapped name or use the original role
};

const Profile = () => {
  const { user: currentUser } = useSelector((state) => state.auth);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ padding: '20px', marginTop: '40px', textAlign: 'center', paddingTop: '30px', boxSizing: 'border-box' }}>
        <Avatar sx={{ bgcolor: deepPurple[500], width: 80, height: 80, fontSize: 40, margin: 'auto', marginBottom: '10px' }}>
          {currentUser.username.charAt(0)}
        </Avatar>
        <Typography variant="h4" gutterBottom>
          {currentUser.username}
        </Typography>
        <Divider />
        <List>
          <ListItem>
            <Typography variant="body1">
              <strong>Id:</strong> {currentUser.id}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              <strong>Email:</strong> {currentUser.email}
            </Typography>
          </ListItem>
        </List>
        <Divider />
        <Typography variant="body1" gutterBottom>
          <strong>Authorities:</strong>
        </Typography>
        <List>
          {currentUser.roles &&
            currentUser.roles.map((role, index) => (
              <ListItem key={index}>
                <Typography variant="body2">{getRoleDisplayName(role)}</Typography>
              </ListItem>
            ))}
        </List>
      </Paper>
    </Container>
  );
};

export default Profile;
