import React, { useState, useEffect } from "react";
import axios from 'axios';
import CountUp from 'react-countup';

import { styled } from '@mui/material/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { API_URL } from '../apiConfig';
import { useInView } from 'react-intersection-observer';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import { Parallax } from 'react-parallax';

import CustomTypography from './UI/Typography/CustomTypography';
import NewsletterButton from './UI/Buttons/NewsLetterButton';
import NewsletterModal from './UI/Modal/NewsLetterModal';
import CustomAlert from './UI/Alerts/CustomAlert';
import FramerMotion from "./UI/Animations/FramerMotion";
import LatestNewsListSection from "./UI/Animations/LatestNewsListSection";
import BannerCarousel from "./UI/Animations/BannerCarousel";
import PartnerCarousel from "./UI/Animations/PartnerCarousel";
import YouTubeWidget from "./UI/Widgets/YouTubeWidget";
import LinkedInWidget from './UI/Widgets/LinkedInWidget';
import TwitterWidget from './UI/Widgets/TwitterWidget';

const theme = createTheme({
  shape: {
    borderRadius: 5
  },
});

const partnerImages = [
  { filename: '1.png', url: 'https://www.cityofathens.gr/who/etaireia-anaptyxis-kai-toyristikis-provolis-athinon-eata/' },
  { filename: '2.png', url: 'https://www.aia.gr/el/traveler/flight-info/rtfi/The' },
  { filename: '3.png', url: 'https://royalapollonialimassol.com' },
  { filename: '4.png', url: 'https://brinks.gr' },
  { filename: '5.png', url: 'https://www.police.gov.cy/police/police.nsf/index_gr/index_gr?opendocument' },
  { filename: '6.png', url: 'https://www.astynomia.gr/' },
  { filename: '7.png', url: 'https://www.feg-touristguides.com/' },
  { filename: '8.png', url: 'https://www.hertz.gr/en/?gad_source=1&gclid=Cj0KCQjw3tCyBhDBARIsAEY0XNnrieqiXWHqTGfCYGsA8E4X9Ie1xPwNwH-tEQUpdLdfzbIi8MBcHZwaApfvEALw_wcB' },
  { filename: '9.png', url: 'https://www.limassolmarina.com/' },
  { filename: '10.png', url: 'https://www.jct.gr/' },
  { filename: '11.png', url: 'https://mdat.gr/' },
  { filename: '12.png', url: 'https://www.policja.pl/pol/english-version/4889,Polish-National-Police.html' },
  { filename: '13.png', url: 'https://www.police-nationale.interieur.gouv.fr/' },
  { filename: '14.png', url: 'https://www.thpa.gr/' },
  { filename: '15.png', url: 'http://www.ubbsla.org/en/' },
  { filename: '16.png', url: 'https://www.uniwa.gr/' },
  { filename: '17.png', url: 'https://mykonos.gr/' },
  { filename: '18.png', url: 'https://kemea.gr/en/' }
];

const bannerImages = [
  '/images/BannerImages/1.jpg',
  '/images/BannerImages/2.jpg',
  '/images/BannerImages/3.jpg'
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#F9F9F9',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,

  display: 'flex', // Make it a flex container
  flexDirection: 'column', // Align children in a column
  alignItems: 'center', // Center items horizontally
  justifyContent: 'center', // Center items vertically
}));

const Home = () => {
  const [openNewsletterForm, setOpenNewsletterForm] = useState(false);
  const [formDataFromModal, setFormDataFromModal] = useState({
    name: '',
    surname: '',
    email: '',
    phone: '',
    organizationName: '',
    comments: '',
  });
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');

  // Function to open the alert
  const handleOpenAlert = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
    setAlertOpen(true);
  };

  // Function to open the newslettermodal
  const handleOpenNewsletterForm = () => {
    setOpenNewsletterForm(true);
  };

  //Function to close the newslettermodal
  const handleCloseNewsletterForm = () => {
    setOpenNewsletterForm(false);
  };

  // Callback function to handle data from the modal
  const handleModalData = (formData) => {
    // You can perform any additional operations with the form data here if needed
    submitFormData(formData); // Pass the form data to the submitFormData function
  };

  // Axios call to send form data to the server
  // Update your submitFormData function to use handleOpenAlert
  const submitFormData = (formData) => {
    axios.post(API_URL + '/partners/create', formData)
      .then((response) => {
        handleOpenAlert('success', 'Form submitted successfully!');
        handleCloseNewsletterForm();
        setFormDataFromModal({
          name: '',
          surname: '',
          email: '',
          phone: '',
          organizationName: '',
          comments: '',
        });
      })
      .catch((error) => {
        console.error('Error saving form data', error);
        handleOpenAlert('error', 'Failed to submit the form. Please try again.');
      });
  };

  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the animation once
    threshold: 0.1, // Trigger when 10% of the component is visible
  });

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xxl">

        {/* Outer Box Inside the Fluid Container */}
        <Box sx={{ flexGrow: 1, bgcolor: 'white' }}>

          {/* Welcome Section */}
          <Grid container>
            <Grid item xs={12}>
              <Item style={{ width: '100%', height: '300px', backgroundImage: 'url("/images/BannerImages/header.jpg")', marginTop: '60px' }}>
                <CustomTypography variant="h3" text="PROJECT HECTOR" color="white" underline={true} />
                <br></br>
                <CustomTypography variant="h4" text="e-(H)ancing Prot(ECT)ion on T(O)uristic Sites in Eu(R)ope" color="white" />
              </Item>
            </Grid>
          </Grid>

          {/* Carousel for Banner Images */}
          <BannerCarousel images={bannerImages} />

          {/* Activities Section */}
          <FramerMotion />

          <Parallax blur={0} bgImage="/images/BannerImages/2.jpg" bgImageAlt="divider" strength={500} style={{ height: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {/* Your content */}
              </Grid>
            </Grid>
          </Parallax>
          {/* <Grid container spacing={2} style={{ paddingTop: '50px' }}>
            <Grid item xs={12}>
              <Item style={{ paddingTop: '130px', paddingBottom: '130px', backgroundImage: 'url("/images/BannerImages/2.jpg")', backgroundSize: 'cover', backgroundPosition: 'center' }}>
              </Item>
            </Grid>
          </Grid> */}

          {/* Latest News Section */}
          <Grid container spacing={2} style={{ paddingTop: '150px' }}>
            <Grid item xs={12}>
              <Box textAlign="center">
                <CustomTypography variant="h3" text="Latest News" color="#0651A0" underlineThickness={3} underline={true} />
              </Box>
            </Grid>
          </Grid>

          {/* Latest News List Section */}
          <LatestNewsListSection />

          {/* Mission and Vision Section */}
          {/* <Parallax blur={0} bgImage="/images/BannerImages/5.jpg" bgImageAlt="divider" strength={500} style={{ height: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                
              </Grid>
            </Grid>
          </Parallax> */}
          <Grid container spacing={2} style={{ paddingTop: '50px' }}>
            <Grid item xs={12}>
              <Item style={{ paddingTop: '130px', paddingBottom: '130px', backgroundImage: 'url("/images/BannerImages/7.jpg")', backgroundPosition: 'center' }}>
              </Item>
            </Grid>
          </Grid>

          <ThemeProvider theme={theme}>
            <Container maxWidth="lg">
              <Grid container spacing={2} style={{ paddingTop: '50px' }}>
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <CustomTypography variant="h3" text="Project Hector" color="#0651A0" underline={true} />
                    <br></br>
                    <CustomTypography variant="h5" text="The HECTOR project (eHancing protECTion of touristic sites in euRope) is a 24-month European research project funded through the European Union’s Internal Security Fund.
                  The project’s coordinator is the Hellenic Police in an expanded form of consortium (18 partners – 5 countries), with the participation of law enforcement agencies, research institutions, municipal authorities, and tourism operators.
                  The HECTOR project aims to shield European tourism infrastructure, emphasizing strengthening cooperation between the private and public sectors to define security standards, procedures, and protocols (Security Model Plan for Tourism Industry)." color="#0651A0" />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </ThemeProvider>

          {/* Section with Country Icons */}
          <Grid container spacing={2} style={{ paddingTop: '50px', justifyContent: 'center', alignItems: 'center' }}>
            <Grid item xs={12}>
              <Box textAlign="center">
                {/* <CustomTypography variant="h3" text="Action" color="#0651A0" underline={true} /> */}
              </Box>
            </Grid>
          </Grid>

          <Container maxWidth="lg"> {/* Adjust the maxWidth value as needed */}
            <Grid container spacing={15} style={{ paddingTop: '20px', justifyContent: 'center' }}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Tooltip title="Cyprus" placement="top">
                  <img src="/images/Countries/cyprus.png" alt="Cyprus" style={{ width: '100%', height: 'auto' }} />
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Tooltip title="Greece" placement="top">
                  <img src="/images/Countries/greece.png" alt="Greece" style={{ width: '100%', height: 'auto' }} />
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Tooltip title="France" placement="top">
                  <img src="/images/Countries/france.png" alt="France" style={{ width: '100%', height: 'auto' }} />
                </Tooltip>
              </Grid>
            </Grid>

            <Grid ref={ref} container spacing={2} style={{ paddingTop: '20px', justifyContent: 'center' }}>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Box textAlign="center">
                  <CustomTypography
                    variant="h4"
                    text={
                      inView ? <CountUp start={0} end={5} duration={2} /> : '0'
                    }
                    color="black"
                  />
                  <CustomTypography variant="h6" text="Number of project host countries" color="grey" />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Box textAlign="center">
                  <CustomTypography
                    variant="h4"
                    text={
                      inView ? <CountUp start={0} end={18} duration={2} /> : '0'
                    }
                    color="black"
                  />
                  <CustomTypography variant="h6" text="Number of Partners" color="grey" />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Box textAlign="center">
                  <CustomTypography
                    variant="h4"
                    text={
                      inView ? <CountUp start={0} end={24} duration={2} /> : '0'
                    }
                    color="black"
                  />
                  <CustomTypography variant="h6" text="Duration in months (01.12.2023-31.11.2025)" color="grey" />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Box textAlign="center">
                  <CustomTypography
                    variant="h4"
                    text={
                      inView ? <CountUp start={0} end={2089198.54} duration={2} prefix="€" decimals={2} /> : '€0.00'
                    }
                    color="black"
                  />
                  <CustomTypography variant="h6" text="Total Cost" color="grey" />
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={15} style={{ paddingTop: '20px', paddingBottom: '60px', justifyContent: 'center' }}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Tooltip title="Bulgaria" placement="top">
                  <img src="/images/Countries/bulgaria.png" alt="Bulgaria" style={{ width: '100%', height: 'auto' }} />
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Tooltip title="Poland" placement="top">
                  <img src="/images/Countries/poland.png" alt="Poland" style={{ width: '100%', height: 'auto' }} />
                </Tooltip>
              </Grid>
            </Grid>
          </Container>

          {/* Generic Divider Section */}
          <Parallax blur={0} bgImage="/images/BannerImages/4.jpg" bgImageAlt="divider" strength={500} style={{ height: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {/* Your content */}
              </Grid>
            </Grid>
          </Parallax>
          {/* <Grid container spacing={2} style={{ paddingTop: '50px' }}>
            <Grid item xs={12}>
              <Item style={{ paddingTop: '130px', paddingBottom: '130px', backgroundImage: 'url("/images/BannerImages/4.jpg")', backgroundSize: 'cover', backgroundPosition: 'center' }}>
              </Item>
            </Grid>
          </Grid> */}

          {/* Follow us header Section */}
          <Grid container spacing={2} style={{ paddingTop: '50px' }}>
            <Grid item xs={12}>
              <Box textAlign="center">
                <CustomTypography variant="h4" text="Follow Us" color="#0651A0" underline={true} />
              </Box>
            </Grid>
          </Grid>

          <Container maxWidth="lg"> {/* Adjust the maxWidth value as needed */}
            {/* YouTube Widget Divider Section */}
            <Grid container spacing={2} style={{ paddingTop: '50px' }}>
              <Grid item xs={12} sm={12} md={12}>
                <Item>
                  <YouTubeWidget />
                </Item>
              </Grid>
            </Grid>

            {/* LinkedIn Widget Section */}
            <Grid container spacing={2} style={{ paddingTop: '50px' }}>
              <Grid item xs={12} md={12} lg={12} xl={12} style={{ marginBottom: '20px' }}>
                <Item>
                  <LinkedInWidget />
                </Item>
              </Grid>
            </Grid>

            {/* Twitter Widget Section */}
            <Grid container spacing={2} style={{ paddingTop: '50px' }}>
              <Grid item xs={12} sm={12} md={12}>
                <Item>
                  <TwitterWidget />
                </Item>
              </Grid>
            </Grid>
          </Container>

          {/* Project Partners Title Section */}
          <Grid container spacing={2} style={{ paddingTop: '50px' }}>
            <Grid item xs={12}>
              <Box textAlign="center">
                <CustomTypography variant="h3" text="Partners" color="#0651A0" underline={true} />
              </Box>
            </Grid>
          </Grid>

          <ThemeProvider theme={theme}>
            <Container maxWidth="lg"> {/* Adjust the maxWidth value as needed */}
              {/* Project Partners Logos Section */}
              <Grid container spacing={2} style={{ paddingTop: '50px' }}>
                <Grid item xs={12} sm={12} md={12}>
                  {/* Partners Carousel */}
                  <PartnerCarousel partnerImages={partnerImages} />
                </Grid>
              </Grid>
            </Container>
          </ThemeProvider>

          {/* Join Our Network Section */}
          <Grid container spacing={2} style={{ paddingTop: '50px' }}>
            <Grid item xs={12} sm={6} md={12}>
              <Item style={{ paddingTop: '70px', paddingBottom: '70px' }}>
                <CustomTypography variant="h4" text="Join our Network" color="#0651A0" />
                <CustomTypography variant="h5" text="Join us and enter our Network of Tourist Operations" color="#0651A0" />
                <NewsletterButton variant="contained" onClick={handleOpenNewsletterForm}>Submit my Form</NewsletterButton>
              </Item>
            </Grid>
          </Grid>

          {/* Footer Section */}
          <Grid container spacing={2} style={{ paddingTop: '50px' }}>
            <Grid item xs={12} sm={12} md={12}>
              <Item style={{ paddingTop: '50px', paddingBottom: '50px', backgroundImage: 'url("/images/BannerImages/footer.jpg")', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" padding="20px">
                  <Box display="flex" alignItems="center">
                    <img src="/images/eu-flag.png" alt="European Union Flag" style={{ width: '100px', height: 'auto', marginRight: '10px' }} />
                  </Box>
                  <Box display="flex" flexDirection="column" alignItems="flex-start" flex="1">
                    <CustomTypography variant="h7" text="This project is funded by the European Union’s Internal Security Fund — Police." color="white" />
                  </Box>
                  <Box display="flex" flexDirection="column" alignItems="center" flex="1">
                    <CustomTypography variant="h7" text="Grant Agreement No. 101100558 — PROJECT HECTOR" color="white" />
                  </Box>
                  <Box display="flex" flexDirection="column" alignItems="flex-end" flex="1">
                    <CustomTypography variant="h7" text="The content of this webpage represents the views of the author only and is his/her sole responsibility. The European Commission does not accept any responsibility for use that may be made of the information it contains." color="white" />
                  </Box>
                </Box>
              </Item>
            </Grid>
          </Grid>

        </Box>
        {/* Newsletter Form Modal */}
        <NewsletterModal modalTitle={'Join Our Network of Tourist Operations'} open={openNewsletterForm} onClose={handleCloseNewsletterForm} onUpdateSuccess={handleModalData} />

        {/* Use the AlertSnackbar component */}
        <CustomAlert
          open={alertOpen}
          type={alertType}
          message={alertMessage}
          onClose={() => setAlertOpen(false)}
        />
      </Container>
    </ThemeProvider>
  );
};

export default Home;