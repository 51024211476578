import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

function CustomAlert({ open, type, message, onClose }) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}  // Set the anchorOrigin to position on the right
    >
      <MuiAlert elevation={6} variant="filled" onClose={onClose} severity={type}>
        {message}
      </MuiAlert>
    </Snackbar>
  );
}

export default CustomAlert;
