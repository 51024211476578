import React, { useState } from 'react';
import Button from '@mui/material/Button';
import ItemModal from '../Modal/ItemModal';
import NewsLetterModal from '../Modal/NewsLetterModal';

const AddItemButton = ({ modalTitle, onAddItem, buttonTitle, isFromBoardAdminEvents, isFromBoardAdminActions, isFromBoardAdminPartners, onUpdateSuccess }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  //TODO:Must Change naming in handleUpdateSuccess/onUpdateSuccess
  const handleUpdateSuccess = (formData) => {
    // Execute the onUpdateSuccess callback with the updated data
    onUpdateSuccess(formData);
  };

  return (
    <div style={{ paddingBottom: '20px' }}>
      <Button variant="contained" color="primary" onClick={handleOpenModal}>
        {buttonTitle}
      </Button>
      {isModalOpen && (
        <>
          {isFromBoardAdminPartners ? (
            <NewsLetterModal
              onClose={handleCloseModal}
              open={isModalOpen}
              onUpdateSuccess={handleUpdateSuccess}
              modalTitle={modalTitle}
            />
          ) : (
            <ItemModal
              onClose={handleCloseModal}
              onAddItem={onAddItem}
              onCancel={handleCloseModal}
              modalTitle={buttonTitle}
              initialTitle=""
              initialShortDescription=""
              initialLongDescription=""
              initialIsEventOpen={false}
              isFromBoardAdminEvents={isFromBoardAdminEvents}
              isFromBoardAdminActions={isFromBoardAdminActions}
            />
          )}
        </>
      )}
    </div>
  );
};

export default AddItemButton;
