// Create a new file, let's say authUtils.js
import UserService from "../../services/user.service";
import EventBus from "../../common/EventBus";

export const checkAuthenticationAdmin = async () => {
  try {
    const response = await UserService.getAdminBoard();
    return response.data;
  } catch (error) {
    const errorMessage =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();

    // Log the user out if authentication fails
    if (error.response && (error.response.status === 403 || error.response.status === 401)) {
      EventBus.dispatch("logout");
    }

    throw new Error(errorMessage);
  }
};

export const checkAuthenticationUser = async () => {
  try {
    const response = await UserService.getUserBoard();
    return response.data;
  } catch (error) {
    const errorMessage =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();

    // Log the user out if authentication fails
    if (error.response && (error.response.status === 403 || error.response.status === 401)) {
      EventBus.dispatch("logout");
    }

    throw new Error(errorMessage);
  }
};

export const checkAuthenticationModerator = async () => {
  try {
    const response = await UserService.getModeratorBoard();
    return response.data;
  } catch (error) {
    const errorMessage =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();

    // Log the user out if authentication fails
    if (error.response && (error.response.status === 403 || error.response.status === 401)) {
      EventBus.dispatch("logout");
    }

    throw new Error(errorMessage);
  }
};


