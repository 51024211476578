import React from 'react';
import Grid from '@mui/material/Grid';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import CustomCard from '../Cards/CustomCard'; // Adjust the path as needed
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#F9F9F9',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,

    display: 'flex', // Make it a flex container
    flexDirection: 'column', // Align children in a column
    alignItems: 'center', // Center items horizontally
    justifyContent: 'center', // Center items vertically
}));

const cardVariants = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
};

const FramerMotion = () => {
    const { ref: ref1, inView: inView1 } = useInView({ triggerOnce: true });
    const { ref: ref2, inView: inView2 } = useInView({ triggerOnce: true });
    const { ref: ref3, inView: inView3 } = useInView({ triggerOnce: true });
    const { ref: ref4, inView: inView4 } = useInView({ triggerOnce: true });

    return (
        <Grid container spacing={2} style={{ paddingTop: '50px', paddingBottom: '50px', justifyContent: 'center', alignItems: 'stretch' }}>
            <Grid item xs={12} sm={6} md={6} lg={2}>
                <Item style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <motion.div
                        ref={ref1}
                        initial="hidden"
                        animate={inView1 ? 'visible' : 'hidden'}
                        variants={cardVariants}
                    >
                        <CustomCard
                            image="/images/ContainerImages/1.jpg"
                            title="green iguana"
                            fontColor="#0651A0"
                            heading="Security and Planning"
                            body="As part of the HECTOR project, vulnerability assessments on various tourist sites will be conducted to evaluate potential security threats."
                            action1="Learn More"
                            maxWidth={240}
                        />
                    </motion.div>
                </Item>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2}>
                <Item style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <motion.div
                        ref={ref2}
                        initial="hidden"
                        animate={inView2 ? 'visible' : 'hidden'}
                        variants={cardVariants}
                    >
                        <CustomCard
                            image="/images/ContainerImages/2.jpg"
                            title="green iguana"
                            fontColor="#0651A0"
                            heading="Technological Solutions"
                            body="During the security planning process, HECTOR project evaluates the physical protection of premises and examines innovative technological solutions that can be implemented for enhanced safety and security."
                            action1="Learn More"
                            maxWidth={240}
                        />
                    </motion.div>
                </Item>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2}>
                <Item style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <motion.div
                        ref={ref3}
                        initial="hidden"
                        animate={inView3 ? 'visible' : 'hidden'}
                        variants={cardVariants}
                    >
                        <CustomCard
                            image="/images/ContainerImages/3.jpg"
                            title="green iguana"
                            fontColor="#0651A0"
                            heading="Interoperability and Collaboration"
                            body="HECTOR Project seeks to elevate the security awareness and culture of both tourism operators and tourists via a comprehensive European campaign and a Joint Training Programme based on the previous mentioned contemporary Security Model Plan."
                            action1="Learn More"
                            maxWidth={240}
                        />
                    </motion.div>
                </Item>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2}>
                <Item style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <motion.div
                        ref={ref4}
                        initial="hidden"
                        animate={inView4 ? 'visible' : 'hidden'}
                        variants={cardVariants}
                    >
                        <CustomCard
                            image="/images/ContainerImages/4.jpg"
                            title="green iguana"
                            fontColor="#0651A0"
                            heading="Awareness and Education"
                            body="The project will create a standard security model for tourist sites, providing procedures, recommendations, good practices, and guidelines."
                            action1="Learn More"
                            maxWidth={240}
                        />
                    </motion.div>
                </Item>
            </Grid>
        </Grid>
    );
};

export default FramerMotion;
