import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Login from "./app/components/LoginAndRegistration/Login";
import Register from "./app/components/LoginAndRegistration/Register";
import Home from "./app/components/Home";
import News from "./app/components/News";
import NewsPreview from "./app/components/UI/PreviewPages/NewsPreview";
import Actions from "./app/components/Actions";
import ActionsPreview from "./app/components/UI/PreviewPages/ActionsPreview";
import Events from "./app/components/Events";
import EventsPreview from "./app/components/UI/PreviewPages/EventsPreview";
import About from "./app/components/About";
import Contact from "./app/components/Contact";

import Profile from "./app/components/Profile";
import BoardUser from "./app/components/RoleBoards/BoardUser/BoardUser";
import BoardModerator from "./app/components/RoleBoards/BoardModerator/BoardModerator";
import BoardAdminNews from "./app/components/RoleBoards/BoardAdmin/BoardAdminNews";
import BoardAdminActions from "./app/components/RoleBoards/BoardAdmin/BoardAdminActions";
import BoardAdminEvents from "./app/components/RoleBoards/BoardAdmin/BoardAdminEvents";
import BoardAdminPartners from "./app/components/RoleBoards/BoardAdmin/BoardAdminPartners";

import NotFoundPage from './app/components/UI/NotFound/NotFoundPage';

import { logout } from "./app/slices/auth";

import EventBus from "./app/common/EventBus";
import Navbar from "./app/components/UI/NavBar/Navbar";

const App = () => {
  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [loading, setLoading] = useState(true);
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      if (currentUser) {
        setShowModeratorBoard(currentUser.roles.includes("ROLE_MODERATOR"));
        setShowAdminBoard(currentUser.roles.includes("ROLE_ADMIN"));
      } else {
        setShowModeratorBoard(false);
        setShowAdminBoard(false);
      }

      EventBus.on("logout", () => {
        logOut();
      });

      setLoading(false);
    };

    fetchData();

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut]);

  if (loading) {
    return null;
  }

  return (
    <Router>
      <div>
        <Navbar
          showModeratorBoard={showModeratorBoard}
          showAdminBoard={showAdminBoard}
          currentUser={currentUser}
          logOut={logOut}
        />

        <div>
          <Routes>
            {/* PUBLIC ROUTES */}
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/news" element={<News />} />
            <Route path="/news/:id" element={<NewsPreview />} />
            <Route path="/actions" element={<Actions />} />
            <Route path="/actions/:id" element={<ActionsPreview />} />
            <Route path="/events" element={<Events />} />
            <Route path="/events/:id" element={<EventsPreview />} />
            <Route path="/project" element={<About />} />
            <Route path="/contact" element={<Contact />} />

            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/profile" element={<Profile />} />

            {/* RESTRICTED ROUTES */}
            <Route path="/user" element={currentUser ? <BoardUser /> : <Navigate to="/home" />} />
            <Route path="/mod" element={showModeratorBoard ? <BoardModerator /> : <Navigate to="/home" />} />
            <Route path="/adminNews" element={showAdminBoard ? <BoardAdminNews /> : <Navigate to="/home" />} />
            <Route path="/adminActions" element={showAdminBoard ? <BoardAdminActions /> : <Navigate to="/home" />} />
            <Route path="/adminEvents" element={showAdminBoard ? <BoardAdminEvents /> : <Navigate to="/home" />} />
            <Route path="/adminPartners" element={showAdminBoard ? <BoardAdminPartners /> : <Navigate to="/home" />} />

            {/* 404 NOT FOUND */}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
