import React from 'react';
import { Modal, Backdrop, Fade, Box, Typography, IconButton, Grid, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ItemShowCaseModal = ({ open, onClose, title, date, longDescription, file, isCalledFromActionsCard }) => {
	const theme = useTheme();
	const isSmallerScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const getModalWidth = () => {
		return isSmallerScreen ? '90%' : '60%'; // Adjust the width based on your preference
	};

	return (
		<Modal
			open={open}
			onClose={onClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						bgcolor: 'background.paper',
						boxShadow: 24,
						p: 4,
						width: getModalWidth(),
						maxHeight: '90%',
						overflow: 'auto',
						overflowY: 'scroll',  // Enable y-axis scrolling
						overflowX: 'hidden',  // Disable x-axis scrolling
					}}
				>
					<IconButton
						edge="end"
						color="inherit"
						onClick={onClose}
						sx={{ position: 'absolute', top: 0, right: 0 }}
					>
						<CloseIcon />
					</IconButton>

					<Grid container spacing={2}>
						{!isCalledFromActionsCard ? (
							<>
								<Grid item xs={12} sm={12} md={12} lg={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
									{/* Image Column */}
									<img
										src={file}
										alt={title}
										style={{ width: '100%', height: 'auto', maxHeight: '300px', objectFit: 'cover' }}
									/>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
									{/* Title Column */}
									<Typography variant="h4" component="div" gutterBottom sx={{ textTransform: 'uppercase' }}>
										{title}
									</Typography>
									{/* Date Column */}
									<Typography variant="subtitle1" gutterBottom sx={{ color: 'text.secondary' }}>
										{date}
									</Typography>
								</Grid>
							</>
						) : (
							<>
								<Grid item xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
									{/* Title Column */}
									<Typography variant="h4" component="div" gutterBottom sx={{ textTransform: 'uppercase' }}>
										{title}
									</Typography>
									{/* Date Column */}
									<Typography variant="subtitle1" gutterBottom sx={{ color: 'text.secondary' }}>
										{date}
									</Typography>
								</Grid>
							</>
						)}

						<Grid item xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							{/* Description Column */}
							<Typography variant="body1" gutterBottom sx={{ fontSize: '14px', wordWrap: 'break-word', maxWidth: '100%' }}>
								{longDescription ? (
									<span dangerouslySetInnerHTML={{ __html: longDescription }} />
								) : (
									"No description available"
								)}
							</Typography>
						</Grid>
						{isCalledFromActionsCard && (
							<Grid item xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								{/* PDF Column */}
								<embed
									src={file} // file represents the file given from the parent wether is an file or a pdf
									type="application/pdf"
									width="100%"
									height="600"
								/>
							</Grid>
						)}
					</Grid>
				</Box>
			</Fade>
		</Modal>
	);
};

export default ItemShowCaseModal;
