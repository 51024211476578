import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

import CustomTypography from '../Typography/CustomTypography';

const theme = createTheme({
  shape: {
    borderRadius: 5
  },
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#F9F9F9',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,

  display: 'flex', // Make it a flex container
  flexDirection: 'column', // Align children in a column
  alignItems: 'center', // Center items horizontally
  justifyContent: 'center', // Center items vertically
}));


const NotFoundPage = () => {

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1, bgcolor: 'white' }}>
        <Grid container spacing={2}>

          {/* About Us Section */}
          <Grid container spacing={2} style={{ paddingTop: '50px' }}>
            <Grid item xs={12}>
              <Item style={{ paddingTop: '130px', paddingBottom: '130px', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <CustomTypography variant="h4" text="Oops Page not found" color="teal" />
                <CustomTypography variant="body1" text="It seems like the page you are looking for does not exist." color="black" />
                <Link to="/">Go back home</Link>
              </Item>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default NotFoundPage;
