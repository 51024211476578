import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Modal, Backdrop, Fade, Box, Typography, IconButton, Grid, useMediaQuery, useTheme, CircularProgress } from '@mui/material';
import { API_URL } from '../../../apiConfig';

const NewsPreview = () => {
  const { id } = useParams();
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedNews, setSelectedNews] = useState({ imageURL: null });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNewsById = (id) => {
      axios.get(`${API_URL}/news/${id}`)
        .then((response) => {
          setSelectedNews(response.data);
        })
        .catch((error) => {
          console.error('Error fetching news information:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    if (id) {
      fetchNewsById(id);
    }
  }, [id]);

  const getModalWidth = () => {
    return isSmallerScreen ? '90%' : '60%';
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        width: getModalWidth(),
        maxHeight: '90%',
        overflow: 'auto',
        overflowY: 'scroll',
        overflowX: 'hidden',
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : selectedNews.imageURL ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img
                src={'../' + selectedNews.imageURL}
                alt={selectedNews.title}
                style={{ width: '100%', height: 'auto', maxHeight: '300px', objectFit: 'cover' }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Typography variant="h4" component="div" gutterBottom sx={{ textTransform: 'uppercase' }}>
                {selectedNews.title}
              </Typography>
              <Typography variant="subtitle1" gutterBottom sx={{ color: 'text.secondary' }}>
                {selectedNews.updatedAt &&
                  new Date(selectedNews.updatedAt).toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  })}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant="body1" gutterBottom sx={{ fontSize: '14px', wordWrap: 'break-word', maxWidth: '100%' }}>
                {selectedNews.longDescription ? (
                  <span dangerouslySetInnerHTML={{ __html: selectedNews.longDescription }} />
                ) : (
                  "No description available"
                )}
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : (
        <Typography variant="body1" gutterBottom>
          No news Found.
        </Typography>
      )}

    </Box>
  );
};

export default NewsPreview;
