import React, { useState } from 'react';
import axios from 'axios';

import { API_URL } from '../../../apiConfig';

import { styled } from '@mui/system';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import NewsShowCaseModal from '../Modal/ItemShowCaseModal';
import ShareModal from '../Modal/ShareModal';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

// Import statements...

const NewsComplexCard = ({
  id,
  avatarText,
  avatarBgColor,
  actionIcon,
  title,
  subheader,
  image,
  content,
  viewAction,
  shareAction,
  methodContent,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);

  // Fetch Data with specific News ID
  const fetchNewsById = (id) => {
    axios.get(`${API_URL}/news/${id}`)
      .then((response) => {
        setSelectedNews(response.data);
      })
      .catch((error) => {
        console.error('Error fetching news information:', error);
      });
  };

  // Format The Date for the specific News id you fetched
  const formattedDate = selectedNews ?
    new Date(selectedNews.updatedAt).toLocaleDateString('el-GR', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }) :
    '';

  // Function to be called when viewAction is triggered
  const handleLearnMoreClick = () => {
    fetchNewsById(id);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // Function to open the share modal
  const handleOpenShareModal = () => {
    setShareModalOpen(true);
  };

  // Function to close the share modal
  const handleCloseShareModal = () => {
    setShareModalOpen(false);
  };

  // Function to generate a dynamic link based on the news title
  const generateDynamicLink = (title) => {
    // Replace spaces with dashes and convert to lowercase for a URL-friendly format
    const formattedTitle = title.replace(/\s+/g, '-').toLowerCase();
    return API_URL + `/news/+${formattedTitle}-${id}`;
  };

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardHeader
        sx={{
          height: 150,
          overflow: 'hidden',
        }}
        titleTypographyProps={{
          variant: 'h6',
          style: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal', // Allow the title to wrap to the next line
          },
        }}
        action={
          <IconButton aria-label="settings">
            {/* {actionIcon} */}
          </IconButton>
        }
        title={title.length > 50 ? `${title.slice(0, 50)}...` : title}
        subheader={subheader}
      />

      <CardMedia
        component="img"
        height="194"
        image={image}
        alt="Card Media"
      />
      <CardContent sx={{ height: 100 }}>
        <Typography variant="body2" color="text.secondary">
          {content && content.length > 50 ? `${content.slice(0, 120)}...` : content}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        {/* IconButton for adding to views */}
        <IconButton aria-label="add to views" onClick={() => { fetchNewsById(id); handleLearnMoreClick(); }}>
          <VisibilityIcon />
        </IconButton>
        <IconButton aria-label="share" onClick={handleOpenShareModal}>
          <ShareIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {/* Dynamic height for methodContent section */}
          <Typography paragraph variant="body2" sx={{ maxHeight: 120, overflow: 'hidden' }}>
            {methodContent}
          </Typography>
        </CardContent>
      </Collapse>
      {/* News ShowCase Modal */}
      {selectedNews && (
        <NewsShowCaseModal
          open={modalOpen}
          onClose={handleCloseModal}
          title={title}
          longDescription={selectedNews.longDescription}
          date={formattedDate}
          file={image}
        />
      )}
      <ShareModal
        open={shareModalOpen}
        onClose={handleCloseShareModal}
        link={generateDynamicLink(title)}
        id={id}
        isCalledFrom='news'
      />
    </Card>
  );
};
export default NewsComplexCard;
