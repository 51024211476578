import React, { useState } from 'react';
import Button from '@mui/material/Button';
import ItemModal from '../Modal/ItemModal';
import NewsLetterModal from '../Modal/NewsLetterModal';

const EditItemButton = ({
  onEditItem,
  onFormUpdate,
  selectedItems,
  onUpdateSuccess,
  selectedRowIds,
  isFromBoardAdminEvents,
  isFromBoardAdminActions,
  isFromBoardAdminPartners,
  modalTitle,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
    onEditItem();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleFormUpdate = (formData) => {
    // Call the onFormUpdate callback with the updated data
    onFormUpdate(formData);
  };

  const handleUpdateSuccess = (formData) => {
    // Execute the onUpdateSuccess callback with the updated data
    onUpdateSuccess(formData);
  };

  const isDisabled = selectedRowIds.length !== 1;

  return (
    <div style={{ paddingBottom: '20px' }}>
      <Button variant="contained" color="primary" onClick={handleOpenModal} size="small" disabled={isDisabled}>
        Edit
      </Button>
      {isModalOpen && (
        <>
          {isFromBoardAdminPartners ? (
            <NewsLetterModal
              onClose={handleCloseModal}
              open={isModalOpen}
              onFormUpdate={handleFormUpdate}
              onUpdateSuccess={handleUpdateSuccess}
              selectedItems={selectedItems}
              isFromBoardAdminPartners={isFromBoardAdminPartners}
              modalTitle={modalTitle}
            />
          ) : (
            <ItemModal
              onClose={handleCloseModal}
              onEditItem={onEditItem}
              onFormUpdate={handleFormUpdate}
              onUpdateSuccess={handleUpdateSuccess}
              modalTitle="Update Item Info"
              initialTitle=""
              initialShortDescription=""
              initialLongDescription=""
              selectedItems={selectedItems}
              isFromBoardAdminEvents={isFromBoardAdminEvents}
              isFromBoardAdminActions={isFromBoardAdminActions}
            />
          )}
        </>
      )}
    </div>
  );
};

export default EditItemButton;
